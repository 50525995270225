export default function PopupLabel({ id, title, onDelete = () => {} }) {
  const deleteFunction = async () => {};
  return (
    <div className="h-7 my-1 flex items-center pl-1 rounded-sm bg-gray-300 relative">
      {title}
      <div
        className="w-5 cursor-pointer h-5 flex items-center justify-center font-bold text-white rounded-full float-right  bg-orange-500 absolute right-1 "
        onClick={() => onDelete(id)}
      >
        X
      </div>
    </div>
  );
}
