
import { configureStore } from '@reduxjs/toolkit';
import counterReducer from './counterSlice'; 
import transactionSlice from './transactionSlice';

const store = configureStore({
  reducer: {
    counter: counterReducer, 
    transaction: transactionSlice, 
  },
});

export default store;
