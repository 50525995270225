import { useState, Fragment, useEffect } from "react";
import { addNotification } from "../../Components/Notification/Notification";
import { IconButton, Tooltip } from "@mui/material";

import Token from "../../Database/Token";
import Input from "../../Components/Input/Input";
import Loading from "../../Assets/SVG/Loading";
import AssessmentIcon from "@mui/icons-material/Assessment";

export default function ReportPopUp({
  title = "Report",
  route,
  onLoading = () => {},
  format = [
    { title: "name", target: "name", type: "text" },
    { title: "password", target: "password", type: "password" },
  ],
  data = [{ name: "User", password: "password" }],
  hover = "Report",
  onUpdate = () => {},
}) {
  const [active, setActive] = useState(false);
  const [formValue, setFormValue] = useState(data);
  const [loading, setLoading] = useState(false);
  const [range, setRange] = useState(false);
  const [to, setTo] = useState(false);
  const [from, setFrom] = useState(false);

  const EditFunction = async () => {
    setLoading(true);
    try {
      const response = await Token.patch(route, formValue);
      if (response.data.status) {
        addNotification("Successful booking", response.data.message, "success");
        setLoading(false);
        setActive(false);
        onUpdate();
        onLoading();
      } else {
        addNotification("Error", response.data.message, "warning");
      }
    } catch (error) {
      setLoading(false);
      if (error.response) {
        addNotification(
          "Error",
          error.response.data.message || "An error occurred",
          "warning"
        );
      } else if (error.request) {
        addNotification("Error", "No response received from server", "warning");
      } else {
        addNotification(
          "Error",
          error.message || "An unknown error occurred",
          "warning"
        );
      }
    }
  };

  return (
    <Fragment>
      <Tooltip arrow placement="top" title={hover}>
        <div className="bg-blue-500 cursor-pointer w-10 h-10 flex items-center justify-center rounded-md shadow-md">
          <IconButton
            className="w-10 h-10 rounded-none"
            onClick={() => setActive(!active)}
          >
            <AssessmentIcon className="text-white" />
          </IconButton>
        </div>
      </Tooltip>

      <div
        onClick={() => setActive(false)}
        className={`z-[5] w-screen h-screen overflow-y-auto  fixed inset-0 flex items-center justify-center pt-10 ${
          active ? "visible" : "invisible"
        } bg-black/50`}
      >
        <div
          className={`bg-white rounded-md shadow-2xl transition-all p-3 ${
            active ? "scale-100 opacity-100" : "scale-75 opacity-0"
          } ${
            format.length > 7
              ? "max-w[700px] w-[700px]"
              : "max-w[400px] w-[400px]"
          } `}
          onClick={(e) => e.stopPropagation()}
        >
          <h1 className="text-5xl font-bold text-orange-500 text-center ">
            {title}
          </h1>
          <div
            className={`grid ${
              format.length > 7 ? "grid-cols-2 gap-x-4" : "grid-cols-1"
            }`}
          >
            <p className="font-bold">Select Range</p>
            <Input
              type="select"
              className="w-full h-10 bg-[#f4f6f8] border-[2px] border-gray-300 rounded-sm outline-none focus:border-orange-500 px-2 focus:border-[2px] mb-2"
              options={[
                { id: "day", title: "Day" },
                { id: "week", title: "Week" },
                { id: "month", title: "Month" },
                { id: "quarter", title: "Quarter" },
                { id: "year", title: "Year" },
                { id: "custom", title: "Custom" },
              ]}
              onChange={(e) => setRange(e.target.value)}
            />
          </div>
          {range === "custom" && (
            <div className="grid grid-cols-2 gap-2">
              <div>
                <p className="font-bold">From</p>
                <Input
                  type="date"
                  className="w-full h-10 bg-[#f4f6f8] border-[2px] border-gray-300 rounded-sm outline-none focus:border-orange-500 px-2 focus:border-[2px] mb-2"
                  onChange={(e) => setFrom(e.target.value)}
                />
              </div>
              <div>
                <p className="font-bold">To</p>
                <Input
                  type="date"
                  className="w-full h-10 bg-[#f4f6f8] border-[2px] border-gray-300 rounded-sm outline-none focus:border-orange-500 px-2 focus:border-[2px] mb-2"
                  onChange={(e) => setTo(e.target.value)}
                />
              </div>
            </div>
          )}

          {!loading && (
            <button
              className="outline-none my-[3px] w-full h-10 bg-orange-500 hover:bg-orange-600 focus-visible:ring-2 ring-offset-2 ring-red-500 text-white text-xl rounded-md shadow-md"
              onClick={EditFunction}
            >
              Download
            </button>
          )}
          {loading && (
            <button className="outline-none my-[3px] w-full h-10 bg-orange-500 hover:bg-orange-600 focus-visible:ring-2 ring-offset-2 ring-red-500 text-white text-xl rounded-md shadow-md flex items-center justify-center">
              <Loading />
            </button>
          )}
          <button
            className="w-full h-10 rounded-sm my-2 text-lg bg-white border-[1.5px] border-dashed border-gray-400 text-gray-500 shadow-md outline-none hover:bg-orange-50 focus:bg-orange-50"
            onClick={() => setActive(false)}
          >
            Cancel
          </button>
        </div>
      </div>
    </Fragment>
  );
}
