import { IconButton, Tooltip } from "@mui/material";
import PhoneIcon from "@mui/icons-material/Phone";

export default function CallButton({ hover = "Call", number }) {
  const phoneClick = () => {
    console.log(number)
      window.open(`tel:+91${number}`, "_self");
    // if (number) {
    // } else {
    //   console.error("Phone number is required");
    // }
  };

  return (
    <Tooltip arrow placement="top" title={hover} onClick={phoneClick}>
      <div className="bg-blue-500 cursor-pointer w-10 h-10 flex items-center justify-center rounded-md shadow-md">
        <IconButton className="w-10 h-10 rounded-none">
          <PhoneIcon className="text-white" />
        </IconButton>
      </div>
    </Tooltip>
  );
}
