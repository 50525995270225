import { useState, Fragment } from "react";
import { IconButton, Tooltip } from "@mui/material";
import ImageIcon from "@mui/icons-material/Image";
import FileDownloadIcon from "@mui/icons-material/FileDownload";

export default function ImagePopUp({ hover = "Aadhar", src, file }) {
  const [active, setActive] = useState(false);

  const downloadImage = async () => {
    try {
      if (!file) {
        console.error("File parameter is not provided.");
        return;
      }

      const isPublicPath = !file.includes("/"); // Assuming files without "/" are served from public
      let fileUrl;

      if (isPublicPath) {
        // Directly use public path
        fileUrl = `${window.location.origin}/${file}`;
      } else {
        // Use API path (fallback)
        const apiEndpoint = `/download?file=${encodeURIComponent(file)}`;
        const token = localStorage.getItem("token");

        const response = await fetch(apiEndpoint, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (!response.ok) {
          throw new Error(`Failed to fetch the file. Status: ${response.status}`);
        }

        const blob = await response.blob();
        fileUrl = window.URL.createObjectURL(blob);
      }

      // Trigger download
      const link = document.createElement("a");
      link.href = fileUrl;
      link.download = file.split("/").pop(); // Extract filename
      document.body.appendChild(link);
      link.click();

      // Cleanup (if applicable)
      if (!isPublicPath) window.URL.revokeObjectURL(fileUrl);
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error downloading the image:", error.message);
    }
  };

  return (
    <Fragment>
      <Tooltip arrow placement="top" title={hover}>
        <div className="bg-blue-500 cursor-pointer w-10 h-10 flex items-center justify-center rounded-md shadow-md">
          <IconButton
            className="w-10 h-10 rounded-none"
            onClick={() => setActive(!active)}
          >
            <ImageIcon className="text-white" />
          </IconButton>
        </div>
      </Tooltip>

      <div
        className={`z-[5] w-screen h-screen overflow-y-auto fixed inset-0 flex items-center justify-center pt-10 ${
          active ? "visible" : "invisible"
        } bg-black/50`}
        onClick={() => setActive(false)}
      >
        <div
          className={`bg-white max-w-[400px] min-h-[300px] w-[400px] rounded-md shadow-2xl transition-all p-4 ${
            active ? "scale-100 opacity-100" : "scale-75 opacity-0"
          }`}
          onClick={(e) => e.stopPropagation()}
        >
          <div className="h-10 rounded-md shadow-md bg-orange-500 mb-4 flex items-center">
            {/* Download Button */}
            <IconButton className="w-10 h-10 rounded-none" onClick={downloadImage}>
              <FileDownloadIcon className="text-white" />
            </IconButton>
          </div>
          <img className="w-full h-auto" src={src} alt="Preview" />
        </div>
      </div>
    </Fragment>
  );
}
