import Image from "../../../Assets/Image/Logo.jpg";
import Axios from "../../../Database/Axios";
import Spin from "../../../Assets/SVG/Spin";
import Input from "../../../Components/Input/Input";

import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { addNotification } from "../../../Components/Notification/Notification";

export default function ForgotPassword() {
  const history = useNavigate();
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);

  const loginFunction = async () => {
    try {
      setLoading(true);
      const response = await Axios.get("/forgot-password?email=" + email);

      if (response.data.status) {
        const location = "/forgot-password/otp/" + email;
        history(location);
      }
    } catch (error) {
      setLoading(false);
      if (error.response) {
        addNotification(
          "Error",
          error.response.data.message || "An error occurred",
          "warning"
        );
      } else if (error.request) {
        addNotification("Error", "No response received from server", "warning");
      } else {
        addNotification(
          "Error",
          error.message || "An unknown error occurred",
          "warning"
        );
      }
    } finally {
      setLoading(false);
    }
  };
  return (
    <div className="flex items-center justify-center bg-[#e4e3e3] h-dvh p-3">
      <div className="p-5 max-w-[400px] w-[400px] min-w-[200px] bg-white shadow-md rounded-md">
        <div className="flex justify-center ">
          <img src={Image} alt="not found" className="w-[200px]" />
        </div>
        <h1 className="text-center my-2 text-4xl font-bold text-orange-500">
          Forgot Password?
        </h1>
        <p className="text-center">
          After the submit forgot password request you will recive the mail from
          our website.
        </p>
        <p>Email Address</p>
        <Input
          type="text"
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Enter Email Address"
          name="email"
        />
        <div className="h-2 mt-2">
          <p
            className="float-right cursor-pointer hover:text-gray-600"
            onClick={() => history(-1)}
          >
            Back
          </p>
        </div>
        {!loading && (
          <button
            className="h-9 bg-orange-500 w-full mt-2 text-white rounded-sm outline-none focus-within:bg-orange-600 hover:bg-orange-600 mb-2"
            onClick={loginFunction}
          >
            Submit
          </button>
        )}
        {loading && (
          <button className="float-left h-9 bg-orange-500 w-full mt-2 text-white rounded-sm outline-none focus-within:bg-orange-600 hover:bg-orange-600 mb-2 flex items-center justify-center">
            <Spin />
          </button>
        )}
      </div>
    </div>
  );
}
