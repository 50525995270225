import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import ReactMoment from "react-moment";

import { useAppContext } from "../../../Database/Context";
import UserSidebarNavbar from "../../../Components/Sidebar/UserSidebarNavbar";
import Token from "../../../Database/Token";
import Search from "../../../Components/Search/Search";
import Table from "../../../Components/Table/Table";

// icons
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";

// PopUp
import DeletePopup from "../../../Popups/Delete/DeletePopUp";
import EditPopUp from "../../../Popups/Edit/EditPopUp";
import ImagePopUp from "../../../Popups/Image/ImagePopUp";
import EditPopUpWithIcon from "../../../Popups/EditWithIcon/EditPopUpWithIcon";
import SeatPupUp from "../../../Popups/SeatPopUp/SeatPopUp";

// JSON format
import groupById from "./groupByIdJsonFormat.json";
import editColName from "./editColName.json";
import moneyJsonFormat from "./moneyJsonFormat.json";
import colName from "./GroupJson.json";
import { addNotification } from "../../../Components/Notification/Notification";
import WhatsappOpenButton from "../../../Components/Button/WhatsappOpenButton/WhatsappOpenButton";
import CallButton from "../../../Components/Button/CallButton/CallButton";

export default function GroupById() {
  const [search, setSearch] = useState("");
  const [update, setUpdate] = useState(false);
  const [row, setRow] = useState(5);
  const [page, setPage] = useState(1);
  const [data, setData] = useState([]);
  const [group, setGroup] = useState(false);
  const [bus, setBus] = useState([]);

  const [TableData, setTableData] = useState([]);

  const { dispatch } = useAppContext();
  const params = useParams();

  useEffect(() => {
    const callFunction = async () => {
      dispatch({ type: "SET_LOADING", payload: true });
      try {
        const response = await Token.get(
          `/customers/group/${params.id}&page=${page}`
        );
        if (response.data.status) {
          console.log(response.data);
          setGroup(response.data.group);
          setData(response.data.data.data);
          setTableData(response.data.data);
          setBus(response.data.buses);
        }
      } catch (error) {
        if (error.response) {
          addNotification(
            "Error",
            error.response.data.message || "An error occurred",
            "warning"
          );
        } else if (error.request) {
          addNotification(
            "Error",
            "No response received from server",
            "warning"
          );
        } else {
          addNotification(
            "Error",
            error.message || "An unknown error occurred",
            "warning"
          );
        }
      }
      dispatch({ type: "SET_LOADING", payload: false });
    };
    callFunction();
  }, [search, update, row, page]);

  const searchChange = (e) => {
    setSearch(e);
    setUpdate(!update);
  };

  return (
    <UserSidebarNavbar>
      <div className="h-[calc(100dvh-40px)] w-full p-4 overflow-y-auto">
        <div className="bg-white mb-4 rounded-md overflow-x-auto">
          <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
            <thead className="text-xs text-gray-100 uppercase bg-orange-500">
              <tr>
                {colName.length !== 0 &&
                  colName.map((data, index) => (
                    <th
                      key={index}
                      scope="col"
                      colSpan={1}
                      className="duration-300 pl-2 sm:pl-1 py-2 group h-14 min-w-[75px]"
                    >
                      {data.title}
                    </th>
                  ))}
              </tr>
            </thead>
            <tbody>
              {!group && (
                <tr>
                  <td
                    colSpan={colName.length}
                    className="text-center text-bold text-xl text-gray-800 uppercase py-4 border-[1.5px] border-gray-300"
                  >
                    Data not found
                  </td>
                </tr>
              )}
              {group && (
                <tr
                  className={`pl-2 text-slate-900 border-y-[1.7px] hover:bg-[#f1f1f1]`}
                >
                  {colName.map((data) => (
                    <>
                      {!data.spacial && (
                        <th
                          key={data.title}
                          className="px-4 py-4 md:px-4 sm:px-2 font-medium"
                          scope="row"
                        >
                          {group[data.target]}
                        </th>
                      )}
                    </>
                  ))}
                  <th
                    className="px-4 py-4 md:px-4 sm:px-2 font-medium"
                    scope="row"
                  >
                    <ReactMoment format="DD MMM YYYY">
                      {group["departure_date"]}
                    </ReactMoment>
                  </th>
                  <th
                    className="px-4 py-4 md:px-4 sm:px-2 font-medium"
                    scope="row"
                  >
                    <ReactMoment format="DD MMM YYYY">
                      {group["arrival_date"]}
                    </ReactMoment>
                  </th>
                  <th
                    className="px-4 py-4 md:px-4 sm:px-2 font-medium"
                    scope="row"
                  >
                    {group["remark"]}
                  </th>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <Search className="mb-4" onSearch={searchChange}></Search>

        <div key={TableData}>
          <Table
            data={data}
            button={""}
            updateRow={(e) => {
              setRow(e);
              setPage(e);
            }}
            from={TableData.from}
            to={TableData.to}
            total={TableData.total}
            cPage={TableData.current_page}
            updatePage={(e) => setPage(e)}
            lPage={TableData.last_page}
          >
            <thead className="text-xs text-gray-100 uppercase bg-orange-500">
              <tr>
                {groupById.length !== 0 &&
                  groupById.map((data, index) => (
                    <th
                      key={index}
                      scope="col"
                      colSpan={1}
                      className="duration-300 pl-2 sm:pl-1 py-2 group h-14 min-w-[75px]"
                    >
                      {data.title}
                    </th>
                  ))}
              </tr>
            </thead>

            <tbody>
              {data.length === 0 && (
                <tr>
                  <td
                    colSpan={groupById.length}
                    className="text-center text-bold text-xl text-gray-800 uppercase py-4 border-[1.5px] border-gray-300"
                  >
                    Data not found
                  </td>
                </tr>
              )}
              {data.length !== 0 &&
                data.map((list, index) => (
                  <tr
                    className={`pl-2 text-slate-900 border-y-[1.7px] hover:bg-[#f1f1f1]`}
                    key={list.id}
                  >
                    <th
                      className="px-4 py-4 md:px-4 sm:px-2 font-medium"
                      scope="row"
                    >
                      {index + 1 + (TableData.current_page - 1) * row}
                    </th>
                    {groupById.map((data, index) => (
                      <>
                        {!data.spacial && (
                          <th
                            className="px-4 py-4 md:px-4 sm:px-2 font-medium"
                            scope="row"
                            key={index * 10000}
                          >
                            {list[data.target]}
                          </th>
                        )}
                      </>
                    ))}
                    <th
                      className="px-4 py-4 md:px-4 sm:px-2 font-medium grid grid-cols-4 gap-[2px] min-w-48"
                      scope="row"
                    >
                      <div key={bus}>
                        <SeatPupUp
                          buses={bus}
                          onUpdate={() => setUpdate(!update)}
                          customer_id={list.id}
                          group_id={group.id}
                          data={list}
                          format={moneyJsonFormat}
                          id={list.id}
                          file={list.aadhar}
                          bgButton="bg-green-500"
                          gender={list.gender}
                          trip_id={group.trip_id}
                          seat_id={list.seat_id}
                          seat_title={list.seat_title}
                          seat_transaction_id={list.seat_tripbustransaction_id}
                        />
                      </div>
                      <EditPopUpWithIcon
                        onUpdate={() => setUpdate(!update)}
                        route={`/customers/${list.id}/update-pricing`}
                        data={list}
                        format={moneyJsonFormat}
                        id={list.id}
                        title="Money"
                        file={list.aadhar}
                        bgButton="bg-green-500"
                      >
                        <CurrencyRupeeIcon className="text-white" />
                      </EditPopUpWithIcon>
                      <ImagePopUp
                        onUpdate={() => setUpdate(!update)}
                        route={`/employee/group/${list.id}`}
                        data={list}
                        format={editColName}
                        id={list.id}
                        title="Group"
                        src={"http://localhost:8000/storage/" + list.aadhar}
                        file={list.aadhar}
                      />
                      <EditPopUp
                        onUpdate={() => setUpdate(!update)}
                        route={`/customers/${list.id}`}
                        data={list}
                        format={editColName}
                        id={list.id}
                        title="Edit"
                      />
                      <DeletePopup
                        id={list.id}
                        hover="Delete"
                        route="/customers/"
                        onLoading={() => setUpdate(!update)}
                      />
                      <WhatsappOpenButton number={list.phone} />
                      <CallButton number={list.phone} />
                    </th>
                  </tr>
                ))}
            </tbody>
          </Table>
        </div>
      </div>
    </UserSidebarNavbar>
  );
}
