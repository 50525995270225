import { IconButton, Tooltip } from "@mui/material";

import PrintIcon from "@mui/icons-material/Print";
import { useNavigate } from "react-router-dom";

export default function Printer({id}) {
  const history = useNavigate();

  return (
    <Tooltip arrow placement="top" title="Print" onClick={()=>history("/employee/invoice/"+id+"/print")}>
      <div className="bg-green-500 cursor-pointer w-10 h-10 flex items-center justify-center rounded-md shadow-md">
        <IconButton className="w-10 h-10 rounded-none">
          <PrintIcon className="text-white" />
        </IconButton>
      </div>
    </Tooltip>
  );
}
