import { Font, StyleSheet } from "@react-pdf/renderer";
import source from "../../Assets/fonts/Nunito/static/Nunito-Regular.ttf";

Font.register({ family: "Nunito", src: source });

export const styles = StyleSheet.create({
  page: {
    padding: 20,
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "space-between",
    fontFamily: "Nunito",
    backgroundColor: "white",
    color: "#555555",
  },
  section: {
    width: "50%",
    padding: 10,
    height: "auto",
  },
  section2: {
    width: "100%",
    marginTop: 35,
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gridGap: 20,
    padding: 10,
    height: "auto",
  },
  logo: {
    width: 100,
    height: 100,
    borderRadius: 10,
  },
  header: {
    fontWeight: "bold",
    fontSize: 45,
    color: "#555555",
    textAlign: "left",
  },
  title: {
    fontWeight: "bold",
    fontSize: 20,
    marginBottom: 10,
    marginTop: 5,
  },
  text: {
    fontWeight: "normal",
    fontSize: 12,
    marginTop: 3,
  },
  billTo: {
    fontWeight: "bold",
    fontSize: 15,
    marginBottom: 10,
  },
  table: {},
  th: {
    fontSize: 13,
    fontWeight: "bold",
    backgroundColor: "#666666",
    textAlign: "left",
    color: "white",
    padding: 5,
    border: "none",
  },
  td: {
    fontFamily: "Nunito",
    border: "none",
    paddingLeft: 5,
  },
  tr: {
    borderBottom: "0.5px bold #b0afad",
    fontSize: 12,
    padding: 5,
  },
  totals: {
    marginTop:10,
    display: "flex",
    width: "100%",
    alignItems: "flex-end",
    fontSize: 13,
    // backgroundColor:"red",

  },
  textBold: {
    fontFamily: "Helvetica-Bold",
  },
});
