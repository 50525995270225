import { Fragment, useState } from "react";
import { useAppContext } from "../../Database/Context";
import { Tooltip, IconButton } from "@mui/material";
import Input from "../Input/Input";
import Loading from "../../Assets/SVG/Loading";

import SearchIcon from "@mui/icons-material/Search";

export default function Search({
  SearchList = [],
  onSearch = () => {},
  onActive = () => {},
  onSearchList = () => {},
}) {
  const [search, setSearch] = useState("");
  const [searchOutput, setSearchOutput] = useState({});
  const [active, setActive] = useState(false);
  const [temp, setTemp] = useState(false);

  const { state } = useAppContext();

  const handleInputChange = (event) => {
    setSearch(event.target.value);
    handleKeyPress(event);
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      onSearch(search);
    }
  };

  const handleClear = () => {
    setSearch("");
    setSearchOutput({});
    onSearch("");
    onSearchList({});
    setTemp(!temp);
  };

  return (
    <Fragment>
      <div className="h-12 rounded-md shadow-md bg-white mb-4">
        <input
          type="text"
          name="search"
          className="w-[calc(100%-127.8px)] border-[1.5px] px-2 outline-none border-[#f97316] h-10 my-1 ml-1 rounded-l-md float-left"
          onChange={handleInputChange}
          value={search}
          onKeyDown={handleKeyPress}
          placeholder="Search..."
        />

        <Tooltip
          className="float-right my-1 mr-1"
          arrow
          placement="top"
          title="Multi Search"
        >
          <div className="bg-orange-500 cursor-pointer w-10 h-10 flex items-center justify-center rounded-md shadow-md">
            <IconButton
              className="w-10 h-10 rounded-none"
              onClick={() => {
                onActive(!active);
                setActive(!active);
              }}
            >
              <SearchIcon className="text-white" />
            </IconButton>
          </div>
        </Tooltip>

        <button
          className="h-10 bg-[#f97316] text-white px-3 my-1 float-right mr-1 text-center rounded-r-md"
          onClick={() => onSearch(search)}
        >
          Search
        </button>
      </div>

      {active && (
        <div
          className={`p-4 bg-white duration-200 transition-all mb-4 rounded-md shadow-md`}
        >
          <div
            className="gap-4 grid grid-cols-3 sm:grid-cols-1 md:grid-cols-2"
            key={temp}
          >
            {SearchList.map((list, index) => (
              <div key={index}>
                <p>{list.title}</p>
                <Input
                  type={list.type}
                  name={list.target}
                  options={list.options}
                  onChange={(e) =>
                    setSearchOutput((prevSearch) => ({
                      ...prevSearch,
                      [list.target]: e.target.value,
                    }))
                  }
                />
              </div>
            ))}
          </div>
          {!state.isLoading && (
            <button
              className="w-[100px] h-10 bg-orange-500 text-white rounded-md text-sm font-bold hover:bg-orange-600 transition duration-300 mt-4"
              onClick={() => onSearchList(searchOutput)}
            >
              Search
            </button>
          )}
          {state.isLoading && (
            <button
              className="w-[100px] h-10 flex float-left items-center justify-center bg-orange-500 text-white rounded-md text-sm font-bold hover:bg-orange-600 transition duration-300 mt-4"
              onClick={() => onSearchList(searchOutput)}
            >
              <Loading />
            </button>
          )}
          <button
            className="w-[100px] h-10 bg-[#f4f6f8] text-gray-700 border-[1px] border-gray-300 rounded-md text-sm font-bold transition duration-300 mt-4 ml-4"
            onClick={handleClear}
          >
            Clear
          </button>
        </div>
      )}
    </Fragment>
  );
}
