import React from "react";
import { Page, Text, View, Document, Image } from "@react-pdf/renderer";
import { styles } from "./style";
import Logo from "../../Assets/Image/favicon.png";
import { Table, TR, TH, TD } from "@ag-media/react-pdf-table";
import ReactMoment from "react-moment";
// Create styles

// Create Document Component
export const InvoicePdf = ({
  table = [],
  name,
  date,
  invoice_number,
  total = [],
  advance,
  remaining,
  total_amount,
}) => (
  <Document>
    <Page size="A4" style={styles.page}>
      <View style={styles.section}>
        <Image src={Logo} style={styles.logo} />

        <Text style={styles.title}>Junoon Trekking & Holidays</Text>
        <Text style={styles.text}>Employee Name</Text>
        <Text style={styles.text}>
          402, Sunrise business centre, Nikol - Naroda Rd,
        </Text>
        <Text style={styles.text}>Nikol, Ahmadabad, Gujarat 382350</Text>
        <Text style={styles.text}>India </Text>
      </View>
      <View style={styles.section}>
        <Text style={styles.header}>INVOICE</Text>
      </View>
      <View style={styles.section}>
        <Text style={styles.billTo}>Bill To:</Text>
        <Table style={{ fontSize: 12, marginLeft: 1 }}>
          <TR>
            <TD style={{ border: "none" }}>Customer:</TD>
            <TD style={{ border: "none" }}>{name}</TD>
          </TR>
          <TR>
            <TD style={{ border: "none" }}>Date:</TD>
            <TD style={{ border: "none" }}>{date}</TD>
          </TR>
          <TR>
            <TD style={{ border: "none" }}>Invoice No.:</TD>
            <TD style={{ border: "none" }}>#{invoice_number}</TD>
          </TR>
        </Table>
      </View>
      <Table>
        <TH style={styles.th}>
          <TD style={styles.td}>Trip</TD>
          <TD style={styles.td}>Person</TD>
          <TD style={styles.td}>Rate</TD>
          <TD style={styles.td}>Total</TD>
        </TH>
        {table.map((list) => (
          <TR style={styles.tr}>
            <TD style={styles.td}>{list.trip}</TD>
            <TD style={styles.td}>{list.person}</TD>
            <TD style={styles.td}>{list.rate}</TD>
            <TD style={styles.td}>{list.total}</TD>
          </TR>
        ))}
      </Table>
      <View style={styles.totals}>
        <View
          style={{
            minWidth: "270px",
          }}
        >
          {total.map((item) => (
            <View
              style={{
                flexDirection: "row",
                justifyContent: "space-between",
                marginBottom: "8px",
              }}
            >
              <Text style={item.label === "Total" ? styles.textBold : {}}>
                {item.label}
              </Text>
              <Text style={item.label === "Total" ? styles.textBold : {}}>
                ₹{item.value}
              </Text>
            </View>
          ))}
        </View>
        <Table>
          <TH style={styles.th}>
            <TD style={styles.td}>Advance Amount</TD>
            <TD style={styles.td}>Remaining Amount</TD>
            <TD style={styles.td}>Total</TD>
          </TH>
          <TR style={styles.tr}>
            <TD style={styles.td}>{advance}</TD>
            <TD style={styles.td}>{remaining}</TD>
            <TD style={styles.td}>{total_amount}</TD>
          </TR>
        </Table>
        <View style={{ marginTop: "200px"}}>
          <Text>
            TMC. Advance amount is not refundable so you can not get any return but
            if you want to change date of the trip or exchange at same value
            then we can offer this, advance amount you need to give on{" "}
          </Text>
        </View>
        aegawre
      </View>
    </Page>
  </Document>
);
