import { PDFViewer } from "@react-pdf/renderer";
import { InvoicePdf } from "./InvoicePdf";
import { useEffect, useState } from "react";
import Loading from "../../Assets/SVG/Loading";
import Token from "../../Database/Token";
import { useParams } from "react-router-dom";
import { addNotification } from "../Notification/Notification";

import ReactMoment from "react-moment";

export default function Invoice() {
  const [status, setStatus] = useState("loading");
  const [data, setData] = useState([]);
  const [table, setTable] = useState([]);
  const params = useParams();

  useEffect(() => {
    const callFunction = async () => {
      try {
        const response = await Token.get(`invoices/${params.id}`);
        const dataset = response.data.data;
        console.log("this is invoices");
        console.log(dataset);

        setData(dataset);
        const tableStructure = [
          {
            trip: dataset.trip.title,
            person: dataset.invoice.no_of_person,
            rate: dataset.invoice.per_person_price,
            total: dataset.invoice.total_amount,
          },
        ];
        setTable(tableStructure);
      } catch (error) {
        if (error.response) {
          addNotification(
            "Error",
            error.response.data.message || "An error occurred",
            "warning"
          );
        } else if (error.request) {
          addNotification(
            "Error",
            "No response received from server",
            "warning"
          );
        } else {
          addNotification(
            "Error",
            error.message || "An unknown error occurred",
            "warning"
          );
        }
      } finally {
        setStatus("started");
      }
    };

    callFunction();
  }, []);
  return (
    <div className="w-full h-dvh bg-[#e4e3e3] flex items-center justify-center">
      {status === "started" && (
        <PDFViewer width="100%" height="100%">
          <InvoicePdf
            table={[
              {
                trip: data.trip.title,
                person: data.invoice.no_of_person,
                rate: data.invoice.per_person_price,
                total: data.invoice.total_amount,
              },  
            ]}
            total={ [
              {
                label: "Subtotal",
                value: data.invoice.no_of_person*data.invoice.per_person_price,
              },
              {
                label: "Add Price",
                value: data.invoice.extra_charge,
              },
              {
                label: "Discount",
                value: data.invoice.discount,
              },
              {
                label: "Total",
                value: data.invoice.total_amount,
              },
            ]}
            name={data.invoice.name}
            invoice_number={data.invoice.invoice_number}
            date={data.invoice.date}
            employee={data.employee.name}
            advance={data.invoice.deposit}
            remaining={data.invoice.remaining_amount}
            total_amount={data.invoice.total_amount}
          />
        </PDFViewer>
      )}
      {status === "loading" && (
        <div className="scale-[500%]">
          <Loading />
        </div>
      )}
    </div>
  );
}
