import { useState, useEffect, Fragment } from "react";
import { useParams } from "react-router-dom";
import ReactMoment from "react-moment";
import qs from "qs";

import { useAppContext } from "../../../Database/Context";
import ManagerSidebarNavbar from "../../../Components/Sidebar/ManagerSidebarNavbar";
import Token from "../../../Database/Token";
import Search from "../../../Components/Search/Search";
import Table from "../../../Components/Table/Table";
import SeeBooking from "../../../Components/SeeBookings/SeeBooking";
import DeletePopup from "../../../Popups/Delete/DeletePopUp";
import EditPopUp from "../../../Popups/Edit/EditPopUp";
import StatusPopUp from "../../../Popups/StatusPopUp/StatusPopUp";

import colName from "./colName.json";
import editColName from "./editColName.json";
import SearchList from "./search.json";
import FormLinkCopyButton from "./FormLinkCopyButton";
import PaymentPopUp from "../../../Popups/Payment/PaymentPopUp";
import { addNotification } from "../../../Components/Notification/Notification";

export default function GroupManager() {
  const [search, setSearch] = useState("");
  const [update, setUpdate] = useState(true);
  const [row, setRow] = useState(5);
  const [page, setPage] = useState(1);
  const [data, setData] = useState([]);

  const [searchOutput, setSearchOutput] = useState(false);

  const [TableData, setTableData] = useState([]);

  const { dispatch } = useAppContext();

  useEffect(() => {
    const queryData = {
      search,
      page,
      row,
    };

    const combinedData = {
      ...searchOutput,
      ...queryData,
    };

    const callFunction = async () => {
      dispatch({ type: "SET_LOADING", payload: true });
      try {
        const queryString = qs.stringify(combinedData, { encode: true });
        const response = await Token.get(
          `/employee/group/list?data=${queryString}`
        );
        if (response.data.status) {
          setData(response.data.data.data);
          setTableData(response.data.data);
        }
      } catch (error) {
        if (error.response) {
          addNotification(
            "Error",
            error.response.data.message || "An error occurred",
            "warning"
          );
        } else if (error.request) {
          addNotification(
            "Error",
            "No response received from server",
            "warning"
          );
        } else {
          addNotification(
            "Error",
            error.message || "An unknown error occurred",
            "warning"
          );
        }
      }
      dispatch({ type: "SET_LOADING", payload: false });
    };
    callFunction();
  }, [search, update, row, page, searchOutput]);

  const searchChange = (e) => {
    setSearch(e);
    setUpdate(!update);
  };

  return (
    <ManagerSidebarNavbar>
      <div className="h-[calc(100dvh-40px)] w-full p-4 overflow-y-auto">
        <Search
          onSearch={searchChange}
          SearchList={SearchList}
          onSearchList={(e) => setSearchOutput(e)}
        />

        <div key={TableData}>
          <Table
            data={data}
            button={""}
            updateRow={(e) => {
              setRow(e);
              setPage(1);
            }}
            cPage={TableData.current_page}
            updatePage={(e) => setPage(e)}
            lPage={TableData.last_page}
            from={TableData.from}
            to={TableData.to}
            total={TableData.total}
          >
            <thead className="text-xs text-gray-100 uppercase bg-orange-500">
              <tr>
                {colName.length !== 0 &&
                  colName.map((data, index) => (
                    <th
                      key={index}
                      scope="col"
                      colSpan={1}
                      className="duration-300 pl-2 sm:pl-1 py-2 group h-14 min-w-[75px]"
                    >
                      {data.title}
                    </th>
                  ))}
              </tr>
            </thead>

            <tbody>
              {data.length === 0 && (
                <tr>
                  <td
                    colSpan={colName.length}
                    className="text-center text-bold text-xl text-gray-800 uppercase py-4 border-[1.5px] border-gray-300"
                  >
                    Data not found
                  </td>
                </tr>
              )}
              {data.length !== 0 &&
                data.map((list, index) => (
                  <tr
                    // className={`pl-2 ${ list.status === "debit"?"bg-[#ffdddd] hover:bg-[#ffb0b0]":"bg-[#c2ecff] hover:bg-[#95deff]"} text-slate-900 border-y-[1.7px]`}
                    className={`pl-2 text-slate-900 border-y-[1.7px] hover:bg-[#f1f1f1]`}
                    key={list.id}
                  >
                    <th
                      className="px-4 py-4 md:px-4 sm:px-2 font-medium"
                      scope="row"
                    >
                      {index + 1 + (TableData.current_page - 1) * row}
                    </th>
                    {colName.map((data) => (
                      <>
                        {!data.spacial && (
                          <th
                            className="px-4 py-4 md:px-4 sm:px-2 font-medium"
                            scope="row"
                          >
                            {/* <Status title={list[data.target]} /> */}
                            {list[data.target]}
                          </th>
                        )}
                      </>
                    ))}
                    <th
                      className="px-4 py-4 md:px-4 sm:px-2 font-medium"
                      scope="row"
                    >
                      <ReactMoment format="DD MMM YYYY">
                        {list.departure_date}
                      </ReactMoment>
                    </th>
                    <th
                      className="px-4 py-4 md:px-4 sm:px-2 font-medium"
                      scope="row"
                    >
                      <ReactMoment format="DD MMM YYYY">
                        {list.arrival_date}
                      </ReactMoment>
                    </th>
                    <th
                      className="px-4 py-4 md:px-4 sm:px-2 font-medium"
                      scope="row"
                    >
                      {list.remark}
                    </th>
                    <th
                      className="px-4 py-4 md:px-4 sm:px-2 font-medium"
                      scope="row"
                    >
                      <StatusPopUp
                        api={`/employee/group/changeStatus/${list.id}`}
                        title={list.status}
                        onUpdate={() => setUpdate(!update)}
                      />
                    </th>
                    <th
                      className="px-4 py-4 md:px-4 sm:px-2 font-medium grid grid-cols-5 gap-[2px] w-64"
                      scope="row"
                    >
                      <PaymentPopUp
                        paymentId={list.payment_id}
                        id={list.id}
                        paymentOnline={list.payment_online}
                        paymentCash={list.payment_cash}
                        paymentRemark={list.payment_remark}
                        paymentType={list.payment_type}
                      />
                      <FormLinkCopyButton
                        defaultValue={`http://localhost:3000/customer/form/${list.trip_id}/${list.token}`}
                      />
                      <EditPopUp
                        onUpdate={() => setUpdate(!update)}
                        route={`/employee/group/${list.id}`}
                        data={list}
                        format={editColName}
                        id={list.id}
                        title="Group"
                      />
                      <DeletePopup
                        id={list.id}
                        hover="Delete"
                        route="/employee/group/"
                        onLoading={() => setUpdate(!update)}
                      />
                      <SeeBooking id={list.id} title="Group" />
                    </th>
                  </tr>
                ))}
            </tbody>
          </Table>
        </div>
      </div>
    </ManagerSidebarNavbar>
  );
}
