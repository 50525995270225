import { useState, Fragment, useEffect } from "react";
import { addNotification } from "../../../Components/Notification/Notification";
import { IconButton, Tooltip } from "@mui/material";

import Input from "../../../Components/Input/Input";
import Loading from "../../../Assets/SVG/Loading";
import AddIcon from "@mui/icons-material/Add";
import SeatButton from "./seatButton";

export default function CreateBus({
  title = "Create Bus",
  hover = "Add",
  ButtonTitle = "Add",
}) {
  const [active, setActive] = useState(false);
  const [x, setX] = useState(1);
  const [y, setY] = useState(1);
  const [loading, setLoading] = useState(false);
  const [seat, setSeat] = useState([]);

  const gridStyles = {
    display: "grid",
    gridTemplateColumns: `repeat(${y}, 1fr)`,
    gap: "8px",
    padding: "8px",
    borderRadius: "4px",
  };
  useEffect(() => {
    const newSeats = [];
    for (let row = 0; row < x; row++) {
      for (let col = 0; col < y; col++) {
        const title = String.fromCharCode(65 + col) + (row + 1);
        newSeats.push({
          title,
          x: row,
          y: col,
          type: "seat",
          id: `${row}-${col}`,
        });
      }
    }
    const sortedSeats = newSeats.sort((a, b) => {
      const xComparison = parseInt(a.x) - parseInt(b.x);
      if (xComparison !== 0) {
        return xComparison;
      }
      return parseInt(a.y) - parseInt(b.y);
    });
    setSeat(sortedSeats);
  }, [x, y]);

  const handelClicks = (id) => {
    setSeat((prevSeats) => {
      const updatedSeats = [...prevSeats];
      const clickedIndex = updatedSeats.findIndex((seat) => seat.id === id);

      if (clickedIndex !== -1) {
        const clickedSeat = updatedSeats[clickedIndex];

        if (clickedSeat.type !== "blank") {
          clickedSeat.type = "blank";

          const nextSeatIndex = updatedSeats.findIndex(
            (seat) => seat.x === clickedSeat.x + 1 && seat.y === clickedSeat.y
          );

          if (nextSeatIndex !== -1) {
            updatedSeats[nextSeatIndex].title = clickedSeat.title;
          }

          clickedSeat.title = "";
        }
      }

      return updatedSeats;
    });
  };

  return (
    <Fragment>
      <Tooltip arrow placement="top" title={hover}>
        <div
          className="bg-orange-500 m-3 cursor-pointer h-10 flex items-center justify-center rounded-md shadow-md pr-3"
          onClick={() => setActive(!active)}
        >
          <IconButton className="w-10 h-10 rounded-none">
            <AddIcon className="text-white" />
          </IconButton>
          <label className="text-white font-bold">{ButtonTitle}</label>
        </div>
      </Tooltip>

      <div
        onClick={() => setActive(false)}
        className={`z-[5] w-screen h-screen overflow-y-auto fixed inset-0 flex items-center justify-center pt-10 ${
          active ? "visible" : "invisible"
        } bg-black/50`}
      >
        <div
          className={`bg-white rounded-md shadow-2xl transition-all p-3 ${
            active ? "scale-100 opacity-100" : "scale-75 opacity-0"
          } min-w-[400px] max-w-[800px] w-[400px]`}
          onClick={(e) => e.stopPropagation()}
        >
          <h1 className="text-5xl font-bold text-orange-500 text-center ">
            {title}
          </h1>
          <br />
          <div className="grid grid-cols-4">
            <label className="w-full h-full flex items-center justify-center">
              Row
            </label>
            <Input
              type="number"
              defaultValue={x}
              onChange={(e) => setX(parseInt(e.target.value))}
            />
            <label className="w-full h-full flex items-center justify-center">
              Col
            </label>
            <Input
              type="number"
              defaultValue={y}
              onChange={(e) => setY(parseInt(e.target.value))}
            />
          </div>
          <br />
          <p>Limit</p>
          <div key={`${x}-${y}`}>
            <Input defaultValue={y * x} type="number" />
          </div>
          <div className="flex items-center justify-center my-3 w-auto">
            <div
              className="border-[1.5px] border-gray-300 w-auto"
              style={gridStyles}
            >
              {seat.map((seat, index) => (
                <SeatButton
                  key={index}
                  id={seat.id}
                  title={seat.title}
                  onClick={handelClicks}
                />
              ))}
            </div>
          </div>
          {!loading && (
            <button className="outline-none my-[3px] w-full h-10 bg-orange-500 hover:bg-orange-600 focus-visible:ring-2 ring-offset-2 ring-red-500 text-white text-xl rounded-md shadow-md">
              Save
            </button>
          )}
          {loading && (
            <button className="outline-none my-[3px] w-full h-10 bg-orange-500 hover:bg-orange-600 focus-visible:ring-2 ring-offset-2 ring-red-500 text-white text-xl rounded-md shadow-md flex items-center justify-center">
              <Loading />
            </button>
          )}
          <button
            className="w-full h-10 rounded-sm my-2 text-lg bg-white border-[1.5px] border-dashed border-gray-400 text-gray-500 shadow-md outline-none hover:bg-orange-50 focus:bg-orange-50"
            onClick={() => setActive(false)}
          >
            Cancel
          </button>
        </div>
      </div>
    </Fragment>
  );
}
