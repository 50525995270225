import SuperadminSidebarNavbar from "../../../Components/Sidebar/SuperadminSidebarNavbar";
import SocialMediaCard from "../../../Components/Dashboard/SocialMedit/SocialMedia";
import DashboardCard from "../../../Components/Dashboard/DashboardCard/DashboardCard";
import Graph from "../../../Components/Dashboard/Graph";

import cardFormat from "./cardFormat.json";
import GraphFormat from "./graph.json";
import socialMedia from "./socialMedia.json";
import cardFormat2 from "./cardFormat2.json";
export default function EmployeeDashboard() {
  return (
    <SuperadminSidebarNavbar>
      <div className="grid grid-cols-4 sm:grid-cols-1 md:grid-cols-2 gap-4 m-4">
        {cardFormat.map((list, index) => (
          <DashboardCard
            key={list.title}
            api={list.api}
            title={list.title}
            color={list.color}
          />
        ))}
      </div>
      <div className="grid grid-cols-2 sm:grid-cols-1 gap-3 mx-4">
        {GraphFormat.map((list, index) => (
          <Graph key={index} title={list.title} api={list.api} />
        ))}
      </div>

      <div className="grid grid-cols-2 sm:grid-cols-1 gap-4 m-4">
        {cardFormat2.map((list, index) => (
          <DashboardCard
            key={list.title}
            color={list.color}
            api={list.api}
            title={list.title}
          />
        ))}
      </div>

      <div className="grid grid-cols-3 md:grid-cols-2 sm:grid-cols-1 gap-4 mx-4 mb-4">
        {socialMedia.map((list, index) => (
          <SocialMediaCard key={list.title} title={list.title} api={list.api} />
        ))}
      </div>
    </SuperadminSidebarNavbar>
  );
}
