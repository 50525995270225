import { WhatsApp } from "@mui/icons-material";
import { IconButton, Tooltip } from "@mui/material";

export default function WhatsappOpenButton({ hover = "whatsapp", number }) {

    const openWhatsapp = ()=>{
        window.open(`https://wa.me/+91${number}`, "_blank");
  
    }
  return (
    <Tooltip arrow placement="top" title={hover} onClick={openWhatsapp}>
      <div className="bg-green-500 cursor-pointer w-10 h-10 flex items-center justify-center rounded-md shadow-md">
        <IconButton className="w-10 h-10 rounded-none">
          <WhatsApp className="text-white" />
        </IconButton>
      </div>
    </Tooltip>
  );
}
