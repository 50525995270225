import { useState, useEffect } from "react";

import Token from "../../../Database/Token";
import { addNotification } from "../../Notification/Notification";
import DropDown from "./DropDown";

import PersonIcon from "@mui/icons-material/Person";

export default function DashboardCard({
  title = "Title Hear",
  api = "",
  color = "orange",
}) {
  const [displayAmount, setDisplayAmount] = useState(0);
  const [change, setChange] = useState(100);
  const [time, setTime] = useState("day");

  useState(() => {
    const data = localStorage.getItem(`${title}HomePage`);
    if (data) {
      setTime(data);
    } else {
      localStorage.setItem(`${title}HomePage`, "day");
    }
  }, []);

  useEffect(() => {
    const callFunction = async () => {
      try {
        const response = await Token.get(`${api}?time=${time}`);
        if (response.data.status) {
          setDisplayAmount(response.data.data.current);
          setChange(response.data.data.growth);
        }
      } catch (error) {
        if (error.response) {
          addNotification(
            "Error",
            error.response.data.message || "An error occurred",
            "warning"
          );
        } else if (error.request) {
          addNotification(
            "Error",
            "No response received from server",
            "warning"
          );
        } else {
          addNotification(
            "Error",
            error.message || "An unknown error occurred",
            "warning"
          );
        }
      }
    };
    callFunction();
  }, [time]);

  return (
    <div
      className={`${
        color === "orange"
          ? "bg-[#f97316] hover:bg-[#f95a16]"
          : color === "green"
          ? "bg-green-500 hover:bg-green-600"
          : color === "blue"
          ? "bg-blue-500 hover:bg-blue-600"
          : color === "red"
          ? "bg-red-500 hover:bg-red-600"
          : color === "yellow"
          ? "bg-yellow-500"
          : ""
      }transition-all py-3 pl-3 w-full h-36 rounded-md shadow-md`}
    >
      <p className="text-white text-3xl w-min float-left">{displayAmount}</p>
      <p className="text-gray-200 text-xl float-left ml-2 mt-1">({change}%)</p>
      <DropDown title={title} onClick={(e) => setTime(e)} />
      <br /> <br />
      {/* <PersonIcon style={{'width':'120px',height:'120px'}} className="text-white float-right" /> */}
      <p className="text-gray-200">{title}</p>
      <p className="text-gray-200 uppercase font-bold">{time}</p>
    </div>
  );
}
