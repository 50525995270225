import { createSlice } from "@reduxjs/toolkit";

const counterSlice = createSlice({
  name: "transactions",
  initialState: { value: false },
  reducers: {
    setValueTransaction: (state, action) => {
      state.value = action.payload;
    },
  },
});

export const { setValueTransaction } = counterSlice.actions;
export default counterSlice.reducer;
