import { useState, Fragment } from "react";
import Token from "../../Database/Token";
import { addNotification } from "../../Components/Notification/Notification";

export default function StatusPopUp({
  onUpdate = () => {},
  api,
  title = "pending",
  format = [
    { title: "Pending", target: "pending", color: "yellow" },
    { title: "Confirmed", target: "confirmed", color: "green" },
    { title: "Completed", target: "completed", color: "blue" },
    { title: "Canceled", target: "canceled", color: "red" },
  ],
}) {
  const [active, setActive] = useState(false);

  const checkTarget = (value) => {
    for (let i = 0; i < format.length; i++) {
      if (format[i].target === value) {
        return format[i].color;
      }else if (format[i].title === value){
        return format[i].color; 
      }
    }
    return false;
  };


  const statusChange = async (title) => {
    setActive(false);
    const data = {
      status: title,
    };
    try {
      const response = await Token.patch(api, data);
      if (response.data.status) {
        onUpdate();
        addNotification(
          "Successful booking",
          "Your booking has been Updated",
          "success"
        );
      } else {
        addNotification("Warning", response.data.message, "warning");
      }
    } catch (error) {
      if (error.response) {
        addNotification(
          "Error",
          error.response.data.message || "An error occurred",
          "warning"
        );
      } else if (error.request) {
        addNotification("Error", "No response received from server", "warning");
      } else {
        addNotification(
          "Error",
          error.message || "An unknown error occurred",
          "warning"
        );
      }
    }
  };

  return (
    <Fragment>
      <div
        onClick={() => setActive(true)}
        className={`text-nowrap select-none ${
          checkTarget(title) === "yellow"
            ? "bg-yellow-300 border-yellow-400 text-yellow-600"
            : checkTarget(title) === "green"
            ? "bg-green-500 border-green-600 text-green-100"
            : checkTarget(title) === "blue"
            ? "bg-blue-500 border-blue-600 text-blue-100"
            : "bg-red-500 border-red-600 text-red-100"
        } border-2 w-min rounded-md px-2 py-1 cursor-pointer`}
      >
        {title}
      </div>

      <div
        className={`z-[5] w-screen h-screen fixed inset-0 flex items-center justify-center p-3 ${
          active ? "visible" : "invisible"
        } bg-black/50`}
      >
        <div
          className={`max-w-[450px] w-[450px] bg-white rounded-md shadow-xl p-5 transition-all ${
            active ? "scale-100 opacity-100" : "scale-75 opacity-0"
          }`}
          onClick={(e) => e.stopPropagation()}
        >
          <p className="my-3 text-lg">Do you want to change Status</p>
          <br />

          <div className="grid grid-cols-1 gap-2">
            {format.map((list, index) => (
              <button
                key={index}
                className={`w-full border-[1.75px] h-12 ${
                  list.color === "yellow"
                    ? "bg-yellow-300 border-yellow-600 text-yellow-600 hover:bg-yellow-500 ring-yellow-500"
                    : list.color === "green"
                    ? "bg-green-500 border-green-600 text-green-200 hover:bg-green-600 ring-green-500"
                    : list.color === "blue"
                    ? "bg-blue-500 border-blue-700 text-blue-200 hover:bg-blue-600 ring-blue-500"
                    : list.color === "red"
                    ? "bg-red-500 border-red-600 text-red-200 hover:bg-red-600 ring-red-500"
                    : ""
                } ring-offset-2 outline-none focus-visible:ring-2 text-white text-xl rounded-md shadow-md`}
                onClick={() => statusChange(list.target)}
              >
                {list.title}
              </button>
            ))}
            <button
              className="w-full h-12 bg-[#f4f6f8] hover:bg-[#d0d0d0] text-gray-700 border-[1px] border-gray-300 rounded-md text-xl font-bold transition duration-300"
              onClick={() => setActive(false)}
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </Fragment>
  );
}
