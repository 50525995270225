import React from "react";
import { useState } from "react";
import List from "../../Assets/SVG/List";
import LinkToTag from "./ListToTag";

import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import DashboardRoundedIcon from "@mui/icons-material/DashboardRounded";
import PersonIcon from "@mui/icons-material/Person";
import DirectionsBusIcon from "@mui/icons-material/DirectionsBus";
import GroupIcon from "@mui/icons-material/Group";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import ReceiptIcon from '@mui/icons-material/Receipt';

import LogoutButton from "./LogoutButton";
import FullScreen from "./FullScreen";

const UserSidebarNavbar = (props) => {
  const [sideBarOn, setSideBarOn] = useState(window.innerWidth > 500);

  const data = [
    {
      tooltip: "Account & Settings",
      icon: <PersonIcon />,
      route: "/employee/account",
    },
    {
      tooltip: "Home",
      icon: <DashboardRoundedIcon />,
      route: "/employee/dashboard",
    },
    {
      tooltip: "Group",
      icon: <GroupIcon />,
      route: "/employee/group",
    },
    {
      tooltip: "Trip",
      icon: <DirectionsBusIcon />,
      route: "/employee/trip",
    },
    {
      tooltip: "Customer",
      icon: <PeopleAltIcon />,
      route: "/employee/customer",
    },
    {
      tooltip: "Commission",
      icon: <AccountBalanceWalletIcon />,
      route: "/employee/commission",
    },
    {
      tooltip: "Invoices",
      icon: <ReceiptIcon />,
      route: "/employee/invoices",
    },
  ];


  const handleSideBar = () => {
    setSideBarOn(!sideBarOn);
  };

  const gridStyles = {
    display: "grid",
    gridTemplateColumns: sideBarOn ? "80px 1fr" : "0px 1fr",
  };

  return (
    <div
      className="duration-300 bg-[#e4e3e3] h-dvh max-h-screen w-screen"
      style={gridStyles}
    >
      {/* SideBar */}
      <div
        className={`h-dvh bg-[#f1f1f1] shadow-md duration-300 ${
          sideBarOn ? "" : " -translate-x-20 "
        }`}
      >
        <div className="h-[calc(100%-40px)] text-justify px-3 py-3 text-base font-medium">
          <ul className="leading-10 overflow-y-auto">
            {data.length !== 0 &&
              data.map((data, index) => (
                <li className="my-2" key={index}>
                  <LinkToTag
                    className="cursor-pointer transition transform duration-150 "
                    title={data.tooltip}
                    placement="right"
                    route={data.route}
                  >
                    {data.icon}
                  </LinkToTag>
                </li>
              ))}
          </ul>
        </div>
        <LogoutButton />
      </div>

      {/* Navbar */}
      <div className="h-dvh transition-all max-w-full w-full">
        <div
          className={`h-10 bg-[#f1f1f1] shadow-md flex justify-between transition-all duration-300 w-full`}
        >
          <div className="h-10 w-10 mx-1">
            <Tooltip placement="bottom" arrow title="Menu">
              <IconButton onClick={handleSideBar}>
                <List className={" mx-2 hover:fill cursor-pointer"} />
              </IconButton>
            </Tooltip>
          </div>
          <FullScreen />
        </div>
        {/* Render children here */}
        <div
          className={`duration-300 max-h-[calc(100vh-40px)] overflow-y-auto ${
            sideBarOn ? "max-w-[calc(100vw-80px)]" : "max-w-[calc(100vw)]"
          }`}
        >
          {props.children}
        </div>
      </div>
    </div>
  );
};

export default UserSidebarNavbar;
