import CalculateIcon from "@mui/icons-material/Calculate";

import { useState, Fragment, useEffect } from "react";
import { addNotification } from "../../Components/Notification/Notification";
import { IconButton, Tooltip } from "@mui/material";

import Token from "../../Database/Token";
import Input from "../../Components/Input/Input";
import Loading from "../../Assets/SVG/Loading";

import format from "./colName.json";

export default function TripCalculate({
  tripId,
  title = "Calculate",
  hover = "Edit",
}) {
  const [active, setActive] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(false);

  const callFunction = async () => {
    setLoading(true);
    try {
      const response = await Token.get("invoices/calculate?trip_id=" + tripId);
      console.log(response);
      if (response.data.status) {
        setData(response.data.data);
        return;
      } else {
        addNotification("Error", response.data.message, "warning");
      }
    } catch (error) {
      setLoading(false);
      if (error.response) {
        addNotification(
          "Error",
          error.response.data.message || "An error occurred",
          "warning"
        );
      } else if (error.request) {
        addNotification("Error", "No response received from server", "warning");
      } else {
        addNotification(
          "Error",
          error.message || "An unknown error occurred",
          "warning"
        );
      }
    }
  };

  useEffect(() => {
    if (active === true) {
      callFunction();
    }
  }, [active]);

  return (
    <Fragment>
      <Tooltip arrow placement="top" title={hover}>
        <div className="bg-green-500 cursor-pointer w-10 h-10 flex items-center justify-center rounded-md shadow-md">
          <IconButton
            className="w-10 h-10 rounded-none"
            onClick={() => setActive(!active)}
          >
            <CalculateIcon className="text-white" />
          </IconButton>
        </div>
      </Tooltip>

      <div
        onClick={() => setActive(false)}
        className={`z-[5] w-screen h-screen overflow-y-auto  fixed inset-0 flex items-center justify-center pt-10 ${
          active ? "visible" : "invisible"
        } bg-black/50`}
      >
        <div
          className={`bg-white rounded-md shadow-2xl transition-all p-3 ${
            active ? "scale-100 opacity-100" : "scale-75 opacity-0"
          } ${
            format.length > 7
              ? "max-w[700px] w-[700px] min-h-[300px] "
              : "max-w[400px] w-[400px] min-h-[300px] "
          } ${!data && "flex items-center justify-center"} `}
          onClick={(e) => e.stopPropagation()}
        >
          {!data && (
            <div className="scale-[300%]">
              <Loading />
            </div>
          )}
          {data && (
            <div>
              <h1 className="text-5xl font-bold text-orange-500 text-center ">
                {title}
              </h1>
              <div
                className={`grid ${
                  format.length > 7 ? "grid-cols-2 gap-x-4" : "grid-cols-1"
                }`}
              >
                {data !== 0 &&
                  format.map((list, index) => (
                    <div key={index}>
                      <p className="font-bold">{list.title}</p>
                      <Input
                        type="copy"
                        defaultValue={data[list.target]}
                        disable={true}
                      />
                    </div>
                  ))}
              </div>
              <button
                className="py-2 mt-4 rounded-md text-white text-xl font-bold px-10 bg-orange-500 shadow-md hover:bg-orange-600 focus-visible:ring-2 focus-visible:ring-offset-2 ring-orange-500 outline-none focus-visible:bg-orange-500"
                onClick={() => setActive(false)}
              >
                Close
              </button>
            </div>
          )}
        </div>
      </div>
    </Fragment>
  );
}
