import MarkEmailReadIcon from "@mui/icons-material/MarkEmailRead";
import UPIN from "../../../Components/OTP/UPIN";
import Axios from "../../../Database/Axios";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { useState } from "react";
import { addNotification } from "../../../Components/Notification/Notification";

export default function ForgotPasswordOtp() {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const params = useParams();

  const onPinSubmit = async (e) => {
    setLoading(true);
    const data = {
      email: params.email,
      otp: e,
    };

    try {
      const response = await Axios.post("/verify-otp", data);
      if (response.data.status) {
        const location = "/reset-password/" + response.data.data.token;
        navigate(location);
      }
    } catch (error) {
      setLoading(false);
      if (error.response) {
        addNotification(
          "Error",
          error.response.data.message || "An error occurred",
          "warning"
        );
      } else if (error.request) {
        addNotification("Error", "No response received from server", "warning");
      } else {
        addNotification(
          "Error",
          error.message || "An unknown error occurred",
          "warning"
        );
      }
    }
    setLoading(false);
  };

  const resendFunction = async () => {
    try {
      const response = await Axios.post(
        "/forgot-password?email=" + params.email
      );
      addNotification("Success", response.data.message, "success");
    } catch (error) {
      setLoading(false);
      if (error.response) {
        addNotification(
          "Error",
          error.response.data.message || "An error occurred",
          "warning"
        );
      } else if (error.request) {
        addNotification("Error", "No response received from server", "warning");
      } else {
        addNotification(
          "Error",
          error.message || "An unknown error occurred",
          "warning"
        );
      }
    }
  };

  return (
    <div className="bg-[#e4e3e3] h-screen flex items-center justify-center">
      <div className="">
        <div className="w-[350px] h-[400px] shadow-md rounded-md bg-white p-3">
          <div className="text-center">
            <MarkEmailReadIcon
              style={{ color: "#f97316", width: "100px", height: "100px" }}
            />
          </div>
          <p className="text-center font-bold">Check your email box</p>
          <p className="text-center">We send OTP to your email box</p>
          <UPIN length={6} onPinSubmit={onPinSubmit} />
          <p className="text-center mt-[3px]">
            Didn't get the code?{" "}
            <a
              className="text-orange-500 font-semibold"
              onClick={resendFunction}
            >
              Click to resend.
            </a>
          </p>
          <div className="grid grid-cols-2 gap-3 mt-5">
            <button
              className="text-center h-12 text-gray-500 text-xl rounded-lg  border-2 border-gray-300"
              onClick={() => navigate(-1)}
            >
              cancel
            </button>
            {loading && (
              <button className="text-center h-12 bg-orange-500 text-white text-xl rounded-lg">
                Verify
              </button>
            )}
            {!loading && (
              <button className="text-center h-12 bg-orange-500 text-white text-xl rounded-lg">
                Loading
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
