import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setValue } from "../../Database/Redux/counterSlice";
import { setValueTransaction } from "../../Database/Redux/transactionSlice";

export default function SeatButton({
  title = "",
  activated = false,
  gender = "female",
  disable = false,
  onclick = () => {},
  transaction_id = null,
  seat_id = null,
  customer_id = false,
  self_id = false,
}) {
  const [active, setActive] = useState(activated);
  const count = useSelector((state) => state.counter.value);
  const transaction = useSelector((state) => state.transaction.value);
  const dispatch = useDispatch();

  useEffect(() => {
    if (customer_id === self_id) {
      dispatch(setValue(title));
      dispatch(setValueTransaction(transaction_id));
    }
    if (customer_id === self_id) {
      console.log("button data");
      console.log(seat_id);

      console.log(customer_id);
      console.log(self_id);
    }
  }, []);

  useEffect(() => {
    if (count === title && transaction === transaction_id) {
      setActive(true);
    } else {
      if (disable) {
        return;
      } else {
        setActive(false);
      }
      setActive(false);
    }
  }, [count]);

  return (
    <button
      className={`select-none w-10 rounded-md h-10 uppercase font-bold text-xl font-sans ${
        gender === "male"
          ? `${
              active
                ? customer_id === self_id
                  ? count === title
                    ? " bg-blue-700 text-white"
                    : " text-orange-400 border-[1px] border-orange-500"
                  : seat_id
                  ? " bg-blue-700 text-white"
                  : " bg-blue-500 text-white"
                : " text-orange-400 border-[1px] border-orange-500"
            }`
          : gender === "female"
          ? `${
              active
                ? " bg-pink-500 text-white"
                : " text-orange-400 border-[1px] border-orange-500"
            }`
          : " text-orange-400 border-[1px] border-orange-500"
      }`}
      onClick={() => {
        if (disable && customer_id !== self_id) return;
        if (active) {
          setActive(false);
        } else {
          setActive(true);
          onclick(transaction_id);
          dispatch(setValue(title));
          dispatch(setValueTransaction(transaction_id));
        }
        // if (!count && !active) {
        //   console.log(1);
        //   setActive(true);
        //   dispatch(setValue(title));
        //   onclick(transaction_id);
        // } else if (title === count) {
        //   console.log(2);
        //   setActive(false);
        //   dispatch(setValue(false));
        //   onclick(transaction_id);
        // } else {
        //   setActive(false);
        // }
      }}
    >
      {title}
    </button>
  );
}
