import { useState } from "react";
import ThreeDot from "../../../Assets/SVG/ThreeDot";
import DropFormat from "./format.json";

export default function DropDown({ title, onClick = () => {} }) {
  const [time, setTime] = useState("day");
  const [threeDot, setThreeDot] = useState(false);

  const threeDotClick = (e) => {
    e.stopPropagation();
    setThreeDot(!threeDot);
  };

  const clickTime = async (e) => {
    localStorage.setItem(`${title}HomePage`, e);
    setTime(e);
    onClick(e);
  };
  return (
    <div className="relative" onClick={threeDotClick}>
      <ThreeDot className="float-right mr-1 hover:bg-gray-200/30 duration-300 w-10 h-10 rounded-full p-2 " />
      <div
        className={`${
          threeDot
            ? "opacity-100 scale-100 pointer-events-auto"
            : "opacity-0 scale-75 pointer-events-none"
        } duration-200 w-20 h-auto bg-white rounded-md shadow-md absolute right-3 top-8 cursor-pointer z-[2]`}
      >
        {DropFormat.map((list) => (
          <div

          key={list.title}
            onClick={() => clickTime(list.target)}
            className={`${
              time === list.target ? "bg-orange-500 text-white" : "bg-white"
            } rounded-x-md h-8 hover:bg-orange-500 border-b-[0.5px] border-gray-100 uppercase flex items-center justify-center hover:text-white text-center`}
          >
            {list.title}
          </div>
        ))}
      </div>
    </div>
  );
}
