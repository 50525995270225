import { Routes, Route } from "react-router-dom";

// Main Pages
import Login from "./Pages/Other/Login/Login";
import OTP from "./Components/OTP/OTP";
import NotFound from "./Pages/Other/NotFound/NotFound";
import ForgotPassword from "./Pages/Other/Forgot-Password/Forgot-Password";
import ForgotPasswordMain from "./Pages/Other/Forgot-Password/ResetPassword";
import ForgotPasswordOtp from "./Pages/Other/Forgot-Password/Forgot-Password-Otp";

// Superadmin pages
import SuperadminDashboard from "./Pages/Superadmin/Dashboard/SuperAdminDashboard";
import SuperadminAccount from "./Pages/Superadmin/Account/SuperadminAccount";
import SuperadminTrip from "./Pages/Superadmin/Trip/SuperadminTrip";
import SuperadminGroup from "./Pages/Superadmin/Group/SuperadminGropu";
import SuperadminCustomer from "./Pages/Superadmin/Customer/SuperadminCustomer";
import SuperadminCommission from "./Pages/Superadmin/Commission/SuperadminCommission";
import SuperadminEmployee from "./Pages/Superadmin/Employee/SuperadminEmployee";
import SuperadminManager from "./Pages/Superadmin/Manager/SuperadminManager";
import SuperadminBus from "./Pages/Superadmin/Bus/SuperadminBus";

// Manager pages
import ManagerDashboard from "./Pages/Manager/Dashboard/ManagerDashboard";
import ManagerAccount from "./Pages/Manager/Account/Account";
import GroupManager from "./Pages/Manager/Group/GroupManager";
import TripManager from "./Pages/Manager/Trip/TripManager";
import CustomerManager from "./Pages/Manager/Customer/CustomerManager";

// Employee page
import EmployeeDashboard from "./Pages/Employee/Home/EmployeeDashboard";
import Account from "./Pages/Employee/Account/Account";
import Trip from "./Pages/Employee/Trip/Trip";
import Group from "./Pages/Employee/Group/Group";
import CustomerEmployee from "./Pages/Employee/Customer/CustomerEmployee";
import InvoiceEmployee from "./Pages/Employee/Invoice/InvoiceEmployee";

// Customer Pages
import CustomerForm from "./Pages/Customer/Form";
import GroupById from "./Pages/Employee/GroupById/GroupById";
import EmployeeCommission from "./Pages/Employee/Commission/CommissionEmployee";
import ManagerEmployee from "./Pages/Manager/Employee/ManagerEmployee";
import Invoice from "./Components/Invoice/Invoice";

export default function App() {
  return (
    <Routes>
      {/* Main Pages */}
      <Route path="/" element={<Login />} />
      <Route path="/login" element={<Login />} />
      <Route path="/otp/:email" element={<OTP />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route path="/reset-password/:token" element={<ForgotPasswordMain />} />
      <Route path="/forgot-password/otp/:email" element={<ForgotPasswordOtp />}/>

      {/* Superadmin Pages */}
      <Route path="/superadmin/account" element={<SuperadminAccount />} />
      <Route path="/superadmin/dashboard" element={<SuperadminDashboard />} />
      <Route path="/superadmin/trips" element={<SuperadminTrip />} />
      <Route path="/superadmin/groups" element={<SuperadminGroup />} />
      <Route path="/superadmin/customers" element={<SuperadminCustomer />} />
      <Route path="/superadmin/employees" element={<SuperadminEmployee />} />
      <Route path="/superadmin/commissions" element={<SuperadminCommission />}/>
      <Route path="/superadmin/managers" element={<SuperadminManager />} />
      <Route path="/superadmin/buses" element={<SuperadminBus />} />

      {/* Manager Pages */}
      <Route path="/manager/account" element={<ManagerAccount />} />
      <Route path="/manager/dashboard" element={<ManagerDashboard />} />
      <Route path="/manager/groups" element={<GroupManager />} />
      <Route path="/manager/trips" element={<TripManager />} />
      <Route path="/manager/customers" element={<CustomerManager />} />
      <Route path="/manager/employees" element={<ManagerEmployee />} />

      {/* Employee Pages */}
      <Route path="/employee" element={<EmployeeDashboard />} />
      <Route path="/employee/dashboard" element={<EmployeeDashboard />} />
      <Route path="/employee/account" element={<Account />} />
      <Route path="/employee/group" element={<Group />} />
      <Route path="/employee/group/:id" element={<GroupById />} />
      <Route path="/employee/trip/" element={<Trip />} />
      <Route path="/employee/customer/" element={<CustomerEmployee />} />
      <Route path="/employee/commission/" element={<EmployeeCommission />} />
      
      <Route path="/employee/invoices/" element={<InvoiceEmployee />} />
      <Route path="/employee/invoice/:id/print" element={<Invoice />} />

      {/* Customer Pages */}
      <Route path="/customer/form/:trip/:token" element={<CustomerForm />} />

      {/* Others */}
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}
