import { useState, Fragment, useEffect } from "react";
import { IconButton, Tooltip } from "@mui/material";
import SeatButton from "./seatButton";

// redux imports
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";

export default function ViewBus({ buses = [] }) {
  const [active, setActive] = useState(false);
  const [maxY, setMaxY] = useState(6);
  const [bus, setBus] = useState(buses);

  useEffect(() => {
    const yValues = buses.map((seat) => parseInt(seat.y)).flat();

    const y = Math.max(...yValues);
    setMaxY(y + 1);

    const b = buses.sort((a, b) => {
      const xComparison = parseInt(a.x) - parseInt(b.x);
      if (xComparison !== 0) {
        return xComparison;
      }
      return parseInt(a.y) - parseInt(b.y);
    });
    setBus(b);
  }, [buses]);

  return (
    <Fragment>
      <Tooltip arrow placement="top" title={"bus"}>
        <div className="bg-cyan-400 cursor-pointer w-10 h-10 flex items-center justify-center rounded-md shadow-md">
          <IconButton
            className="w-10 h-10 rounded-none"
            onClick={() => setActive(!active)}
          >
            <RemoveRedEyeIcon className="text-white" />
          </IconButton>
        </div>
      </Tooltip>

      <div
        onClick={() => {
          setActive(false);
        }}
        className={`z-[5] w-screen h-screen overflow-y-auto fixed inset-0 flex items-center justify-center pt-20 ${
          active ? "visible" : "invisible"
        } bg-black/50`}
      >
        <div
          className={`bg-white min-w-[400px] rounded-md shadow-2xl transition-all p-4 ${
            active ? "scale-100 opacity-100" : "scale-75 opacity-0"
          }`}
          onClick={(e) => e.stopPropagation()}
        >
          <h1 className="text-5xl font-bold text-orange-500 text-center ">
            {"this is title"}
          </h1>
          <br />

          <div className="flex items-center justify-center">
            <div
              className={
                "grid grid-rows-" +
                maxY +
                " grid-flow-col gap-1 border-gray-300 p-2 border-[1.5px] rounded-md"
              }
            >
              {buses.map((seat, index) => (
                <div key={index}>
                  {seat.type === "seat" ? (
                    <SeatButton title={seat.title} />
                  ) : (
                    <div></div>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}
