import Token from "../../Database/Token";
import Loading from "../../Assets/SVG/Loading";
import { IconButton, Tooltip } from "@mui/material";
import { useNavigate, Link } from "react-router-dom";

import ExitToAppRoundedIcon from "@mui/icons-material/ExitToAppRounded";
import { useState } from "react";

export default function LogoutButton() {
  const [loading, setLoading] = useState(false);
  const history = useNavigate();
  const logoutFunction = () => {
    const callFunction = async () => {
      setLoading(true);
      try {
        const response = await Token.post("/users/logout");
        if (response.data.status) {
        } else {
          console.log(response.data);
        }
        setLoading(false);
      } catch (error) {
        console.log("error");
        console.log(error);
        setLoading(false);
      } finally {
        localStorage.removeItem("accessToken");
        localStorage.removeItem("user");
        localStorage.removeItem("remember-admin");
        localStorage.removeItem("remember-user");
        history("/login");
      }
    };
    callFunction();
  };

  return (
    <>
      {!loading && (
        <div
          className="text-white h-10 bg-orange-500 shadow-md hover:bg-orange-600 px-4"
          onClick={logoutFunction}
        >
          <Tooltip title="Logout" arrow placement="right">
            <IconButton>
              <ExitToAppRoundedIcon
                sx={{ color: "white" }}
              ></ExitToAppRoundedIcon>
            </IconButton>
          </Tooltip>
        </div>
      )}
      {loading && (
        <div className="text-white h-10 bg-orange-500 shadow-md hover:bg-orange-600 flex items-center justify-center">
          <Loading />
        </div>
      )}
    </>
  );
}
