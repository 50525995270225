import { useEffect, useRef, useState } from "react";
import Input from "../../Components/Input/Input";
import Loading from "../../Assets/SVG/Loading";
import { useParams } from "react-router-dom";
import Axios from "../../Database/Token";
import ReactMoment from "react-moment";
import { addNotification } from "../../Components/Notification/Notification";
import axios from "axios";

export default function CustomerForm() {
  const params = useParams();
  const [loading, setLoading] = useState(false);
  const [loadingMainPage, setLoadingMainPage] = useState("loading");
  const [data, setData] = useState([]);
  const [output, setOutput] = useState({ status: true });
  const [groupId, setGroupId] = useState();
  const [check, setCheck] = useState(false);
  const [pickupOptions, setPickupOptions] = useState([
    { id: 0, title: "Select Options" },
  ]);

  const formDataRef = useRef(new FormData());
  const [aadharPreview, setAadharPreview] = useState(null);

  const token = params.token;
  const trip = params.trip;

  const format = [
    {
      title: "Name",
      target: "name",
      type: "text",
    },
    {
      title: "Email",
      target: "email",
      type: "text",
    },
    {
      title: "Contact Number",
      target: "phone",
      type: "number",
    },
    {
      title: "Full Address",
      target: "address",
      type: "textarea",
    },
    {
      title: "DOB",
      target: "dob",
      type: "date",
    },
    {
      title: "Gender",
      target: "gender",
      type: "select",
      options: [
        { id: 0, title: "Select Gender" },
        { id: "male", title: "Male" },
        { id: "female", title: "Female" },
      ],
    },
    {
      title: "Hometown",
      target: "hometown",
      type: "text",
    },
    {
      title: "Pickup",
      target: "pickup",
      type: "select",
      options: pickupOptions, // Dynamically populated options
    },
    {
      title: "Upload Aadhar Card",
      target: "aadhar",
      type: "file",
    },
  ];

  useEffect(() => {
    const temp_data = JSON.parse(
      localStorage.getItem("customer_form_response_") ||
        '{ "status": false,"token":"none" }'
    );
    console.log(temp_data);
    setOutput(temp_data);
    const callFunction = async () => {
      try {
        const response = await Axios.get(
          `/group/check?token=${token}&trip=${trip}`
        );
        if (response.data.status) {
          setData(response.data.trip);
          if (response.data.trip.pickups) {
            const pickupData = response.data.trip.pickups.map((pickup) => ({
              id: pickup.id,
              title: pickup.location,
            }));
            setPickupOptions([
              { id: 0, title: "Select Options" },
              ...pickupData,
            ]);
            setGroupId(response.data.group_id);
          }
          setLoadingMainPage("success");
        } else {
          setLoadingMainPage("broken");
        }
      } catch (error) {
        console.error("Error fetching data", error);
      }
    };

    callFunction();
  }, [token, trip]);

  const handleChange = (e, target) => {
    const { value, files, type } = e.target;
    const formData = formDataRef.current;

    if (type === "file") {
      const file = files[0];
      formData.set(target, file);

      if (target === "aadhar" && file) {
        const reader = new FileReader();
        reader.onloadend = () => {
          setAadharPreview(reader.result);
        };
        reader.readAsDataURL(file);
      }
    } else {
      formData.set(target, value);
    }
  };

  const handleSubmit = async () => {
    setLoading(true);

    if (!check) {
      addNotification(
        "Warning",
        "Please agree the terms and conditions",
        "warning"
      );
      setLoading(false);
      return false;
    }
    const formData = formDataRef.current;
    formData.set("token", token);
    formData.set("group_id", trip);
    formData.set("group_id", groupId);

    try {
      const response = await axios.post(
        "http://localhost:8000/api/customer/create",
        formData
      );

      if (response.data.status) {
        const modifiedResponse = {
          ...response.data,
          token: token,
        };

        setOutput(modifiedResponse);
        localStorage.setItem(
          "customer_form_response",
          JSON.stringify(modifiedResponse)
        );

        addNotification(
          "Form submitted successfully",
          "Success full submitted",
          "success"
        );
      } else {
        addNotification("Warning", response.data.message, "warning");
      }
    } catch (error) {
      if (error.response) {
        addNotification(
          "Error",
          error.response.data.message || "An error occurred",
          "warning"
        );
      } else if (error.request) {
        addNotification("Error", "No response received from server", "warning");
      } else {
        addNotification(
          "Error",
          error.message || "An unknown error occurred",
          "warning"
        );
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="bg-[#e4e3e3] min-h-screen flex justify-center px-4">
      {!output["status"] &&
        loadingMainPage === "success" &&
        token !== output["token"] && (
          <div className="bg-white p-4 rounded-md shadow-md max-w-[600px] w-[700px] my-10">
            <h1 className="text-3xl font-bold">
              Welcome to Junoon{" "}
              <p className="text-orange-500">
                <ReactMoment format="DD MMM YYYY">
                  {data.departure_date}
                </ReactMoment>{" "}
                {data.title}
              </p>
            </h1>
            <p className="font-bold mt-3 mb-1 ">Personal Information</p>
            <div className="w-full h-[0.5px] opacity-40 bg-gray-600 shadow-md"></div>
            <div className="grid-cols-1" key={pickupOptions}>
              {format.map((field, index) => (
                <div key={index}>
                  <p>{field.title}</p>
                  <Input
                    type={field.type}
                    name={field.target}
                    options={field.options}
                    onChange={(e) => handleChange(e, field.target)}
                  />
                  {field.target === "aadhar" && aadharPreview && (
                    <div className="mt-2">
                      <p className="font-bold">Aadhar Preview:</p>
                      <img
                        src={aadharPreview}
                        alt="Aadhar Preview"
                        className="max-w-xs max-h-48 rounded-md shadow-md"
                      />
                    </div>
                  )}
                </div>
              ))}
              <div className=" mb-1 mt-4">
                <input
                  id="default-checkbox"
                  type="checkbox"
                  value=""
                  className="w-[18px] h-[18px] accent-orange-500 bg-gray-100 border-gray-300 rounded text-white"
                  checked={check}
                  onClick={() => setCheck(!check)}
                />
                <label
                  htmlFor="default-checkbox"
                  className="ms-2 text-sm font-medium text-gray-900 fill-white"
                  onClick={() => setCheck(!check)}
                >
                  I agree & I read all T&C on the website of{" "}
                  <a
                    className="text-blue-800 hover:text-blue-900"
                    target="_blank"
                    href="https://junoontrekking.in/terms-and-conditions/"
                  >
                    https://junoontrekking.in/terms-and-conditions/
                  </a>
                </label>
              </div>
            </div>
            {!loading && (
              <button
                className="py-2 mt-4 rounded-md text-white text-xl font-bold px-10 bg-orange-500 shadow-md hover:bg-orange-600 focus-visible:ring-2 focus-visible:ring-offset-2 ring-orange-500 outline-none focus-visible:bg-orange-500"
                onClick={handleSubmit}
              >
                Save
              </button>
            )}
            {loading && (
              <button className="py-2 mt-4 rounded-md text-white text-xl font-bold px-12 bg-orange-500 shadow-md hover:bg-orange-600 focus-visible:ring-2 focus-visible:ring-offset-2 ring-orange-500 outline-none focus-visible:bg-orange-500">
                <Loading />
              </button>
            )}
          </div>
        )}
      {loadingMainPage === "loading" && (
        <div className="flex items-center justify-center h-screen">
          <div className="scale-[500%]">
            <Loading />
          </div>
        </div>
      )}
      {loadingMainPage === "broken" && (
        <div className="flex items-center justify-center h-screen">
          <p className="text-4xl text-orange-500 font-bold">
            Oops, Something went wrong. Please try again later.
          </p>
        </div>
      )}
      {output && output["token"] === token && loadingMainPage === "success" && (
        <div className="bg-white p-4 rounded-md shadow-md h-min max-w-[600px] w-[700px] my-20 pb-5">
          <h1 className="text-3xl font-bold">
            Welcome <label className="text-orange-500">{"Faiz Rajput"}</label>
          </h1>
          <p className="font-bold mt-3 mb-1 ">
            Your application has been send we will inform you about trip{" "}
          </p>
          <p className="font-bold mt-3 mb-1 ">
            This is your Customer ID:-{" "}
            <label className="text-orange-500">{"23435465"}</label> if you get
            any problem then contact to this number{" "}
            <label className="text-orange-500">{"1234567890"}</label> or mail to{" "}
            <label className="text-orange-500">{"mail@mail.com"}</label> this is
            mail id
          </p>
          <button
            className="py-2 mt-4 rounded-md text-white text-xl font-bold px-10 bg-orange-500 shadow-md hover:bg-orange-600 focus-visible:ring-2 focus-visible:ring-offset-2 ring-orange-500 outline-none focus-visible:bg-orange-500"
            // onClick={callFunction}
          >
            Click
          </button>
        </div>
      )}
    </div>
  );
}
