import Image from "../../../Assets/Image/Logo.jpg";
import Axios from "../../../Database/Axios";

import Spin from "../../../Assets/SVG/Spin";

import { useState } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { addNotification } from "../../../Components/Notification/Notification";

import format from "./format.json";
import Input from "../../../Components/Input/Input";
export default function ForgotPasswordMain() {
  const location = useLocation();
  const history = useNavigate();
  const params = useParams();

  const [loading, setLoading] = useState(false);

  const [data, setData] = useState([]);

  const loginFunction = async () => {
    setLoading(true);
    const jsonData = {
      token: params.token,
      password: data.password,
    };
    try {
      const response = await Axios.post("/reset-password", jsonData);

      if (response.data.status) {
        addNotification(`Send`, "Your password has been change", "success");
        history("/");
      } else {
        addNotification(`Wrong`, response.data.message, "warning");
      }
    } catch (error) {
      setLoading(false);
      if (error.response) {
        addNotification(
          "Error",
          error.response.data.message || "An error occurred",
          "warning"
        );
      } else if (error.request) {
        addNotification("Error", "No response received from server", "warning");
      } else {
        addNotification(
          "Error",
          error.message || "An unknown error occurred",
          "warning"
        );
      }
    }
    setLoading(false);
  };
  return (
    <div className="flex items-center justify-center bg-[#e4e3e3] h-dvh p-3">
      <div className="p-5 max-w-[400px] w-[400px] min-w-[200px] bg-white shadow-md rounded-md">
        <div className="flex items-center justify-center">
          <img className="h-32" src={Image} />
        </div>
        <h1 className="text-center my-2 text-4xl font-bold text-orange-500">
          Reset Password!
        </h1>
        <p className="text-center">
          after you enter the password and conform password you will direct to
          login page.
        </p>
        <div className="h-1"></div>

        {format.map((list, index) => (
          <>
            <p>{list.title}</p>
            <Input
              type={list.type}
              name={list.title}
              onChange={(e) =>
                setData((prev) => ({
                  ...prev,
                  [list.target]: e.target.value,
                }))
              }
            />
          </>
        ))}

        <div className="h-2 mt-2">
          <p
            className="float-right cursor-pointer hover:text-gray-600"
            onClick={() => history(-1)}
          >
            Back
          </p>
        </div>

        <br />
        {!loading && (
          <button
            className="h-9 bg-orange-500 w-full mt-2 text-white rounded-sm outline-none focus-within:bg-orange-600 hover:bg-orange-600 mb-2"
            onClick={loginFunction}
          >
            Submit
          </button>
        )}
        {loading && (
          <button className="h-9 bg-orange-500 w-full mt-2 text-white rounded-sm outline-none focus-within:bg-orange-600 hover:bg-orange-600 mb-2 flex items-center justify-center float-left">
            <Spin />
          </button>
        )}
      </div>
    </div>
  );
}
