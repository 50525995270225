import React, { useState, useEffect } from "react";
import { Stack } from "@mui/material";
import { LineChart } from "@mui/x-charts";
import Token from "../../Database/Token";
import { addNotification } from "../Notification/Notification";

export default function Graph({ api, title }) {
  const [data, setData] = useState([0, 0, 0, 0, 0, 0]);
  const [xData, setXData] = useState([1, 2, 3, 4, 5, 6]);

  useEffect(() => {
    const list1 = localStorage.getItem("employee-dashboard-graph-booking-x");
    const list2 = localStorage.getItem("employee-dashboard-graph-booking-y");
    if (list1 && list2) {
      setXData(JSON.parse(list1));
      setData(JSON.parse(list2));
    }
    const callFunction = async () => {
      try {
        const response = await Token.get(api);
        if (response.data.status) {
          setXData(response.data.data["x-axis"]);
          setData(response.data.data["y-axis"]);
          localStorage.setItem(
            "employee-dashboard-graph-booking-x",
            JSON.stringify(response.data.data["x-axis"])
          );
          localStorage.setItem(
            "employee-dashboard-graph-booking-y",
            JSON.stringify(response.data.data["y-axis"])
          );
        }
      } catch (error) {
        if (error.response) {
          addNotification(
            "Error",
            error.response.data.message || "An error occurred",
            "warning"
          );
        } else if (error.request) {
          addNotification(
            "Error",
            "No response received from server",
            "warning"
          );
        } else {
          addNotification(
            "Error",
            error.message || "An unknown error occurred",
            "warning"
          );
        }
      }
    };
    callFunction();
  }, []);

  const options = {
    title: "Monthly Bookings",
  };

  return (
    <div className="bg-white p-4 md:p-1 sm:p-1 rounded-md shadow-md" key={data}>
      <p className="text-center">{title}</p>
      <Stack sx={{ width: "100%" }}>
        <LineChart
          xAxis={[{ data: xData, scaleType: "point" }]}
          series={[{ data, connectNulls: true }]}
          height={200}
          margin={{ top: 10, bottom: 20 }}
          options={options}
        />
      </Stack>
    </div>
  );
}
