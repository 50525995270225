import { Fragment, useState } from "react";
import GroupIcon from "@mui/icons-material/Group";
import { Tooltip, IconButton } from "@mui/material";
import Input from "../Input/Input";
import Loading from "../../Assets/SVG/Loading";
import Token from "../../Database/Token";
import { addNotification } from "../../Components/Notification/Notification";

export default function BookingLink({ data = [], title = "Title", id }) {
  const [active, setActive] = useState(false);
  const [group, setGroup] = useState("");
  const [link, setLink] = useState("");
  const [loading, setLoading] = useState(false);

  const create = async () => {
    setLoading(true);
    const jsonData = {
      trip_id: id,
      limit: group,
    };
    try {
      const response = await Token.post("/employee/group/create", jsonData);
      if (response.data.status) {
        addNotification("Group created successfully", "Group", "success");
        const data = response.data.group;
        const tempLink = `http://localhost:3000/customer/form/${data.trip_id}/${data.token}`;
        setLink(tempLink);
      }
    } catch (error) {
      if (error.response) {
        addNotification(
          "Error",
          error.response.data.message || "An error occurred",
          "warning"
        );
      } else if (error.request) {
        addNotification("Error", "No response received from server", "warning");
      } else {
        addNotification(
          "Error",
          error.message || "An unknown error occurred",
          "warning"
        );
      }
    }
    setLoading(false);
  };
  return (
    <Fragment>
      <Tooltip arrow placement="top" title="Group">
        <div className="bg-orange-500 cursor-pointer w-10 h-10 flex items-center justify-center rounded-md shadow-md">
          <IconButton
            className="w-10 h-10 rounded-none"
            onClick={() => setActive(!active)}
          >
            <GroupIcon className="text-white" />
          </IconButton>
        </div>
      </Tooltip>
      <div
        className={`z-[5] w-screen h-screen fixed inset-0 flex items-center justify-center p-3 ${
          active ? "visible" : "invisible"
        } bg-black/50`}
      >
        <div
          className={`max-w-[450px] w-[450px] bg-white rounded-md shadow-xl p-5 transition-all ${
            active ? "scale-100 opacity-100" : "scale-75 opacity-0"
          }`}
          onClick={(e) => e.stopPropagation()}
        >
          <h1 className="text-center text-5xl font-bold text-orange-500">
            {title}
          </h1>
          <br />

          <p>Limit</p>
          <Input
            type="number"
            defaultValue={group}
            onChange={(e) => setGroup(e.target.value)}
          />
          <p>Link</p>
          <div key={link}>
            <Input
              type="copy"
              key={link}
              defaultValue={link}
              // onChange={(e) => setGroup(e)}
            />
          </div>
          {!loading && (
            <button
              className="py-2 mt-4 rounded-md text-white text-xl font-bold px-10 bg-orange-500 shadow-md hover:bg-orange-600 focus-visible:ring-2 focus-visible:ring-offset-2 ring-orange-500 outline-none focus-visible:bg-orange-500"
              onClick={create}
            >
              Create
            </button>
          )}
          {loading && (
            <button className="float-left  py-2 mt-4 rounded-md text-white text-xl font-bold px-14 bg-orange-500 shadow-md hover:bg-orange-600 focus-visible:ring-2 focus-visible:ring-offset-2 ring-orange-500 outline-none focus-visible:bg-orange-500">
              <Loading />
            </button>
          )}
          <button
            className="py-2 mt-4 rounded-md text-gray-500 border-[1px] border-dashed border-gray-400 text-xl font-bold px-10 bg-[#f4f6f8]  shadow-md hover:bg-[#eef6ff] focus-visible:ring-2 focus-visible:ring-offset-2 ring-orange-500 outline-none ml-2"
            onClick={() => {
              setActive(false);
              setGroup("");
            }}
          >
            Cancel
          </button>
        </div>
      </div>
    </Fragment>
  );
}
