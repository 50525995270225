import UserSidebarNavbar from "../../../Components/Sidebar/UserSidebarNavbar";
import DashboardCard from "../../../Components/Dashboard/DashboardCard/DashboardCard";
import Graph from "../../../Components/Dashboard/Graph";

import cardFormat from "./cardFormat.json";
import GraphFormat from "./graph.json";

import cardFormat2 from "./cardFormat2.json";
export default function EmployeeDashboard() {
  return (
    <UserSidebarNavbar>
      <div className="grid grid-cols-4 sm:grid-cols-1 md:grid-cols-2 gap-4 m-4">
        {cardFormat.map((list, index) => (
          <DashboardCard key={index} api={list.api} title={list.title} color={list.color} />
        ))}
      </div>
      <div className="grid grid-cols-2 sm:grid-cols-1 gap-3 mx-4">
        {GraphFormat.map((list, index) => (
          <Graph key={index} title={list.title} api={list.api} />
        ))}
      </div>

      <div className="grid grid-cols-2 sm:grid-cols-1 gap-4 m-4">
        {cardFormat2.map((list, index) => (
          <DashboardCard key={index} color={list.color} api={list.api} title={list.title} />
        ))}
      </div>
    </UserSidebarNavbar>
  );
}
