import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { addNotification } from "../../../Components/Notification/Notification";

import Axios from "../../../Database/Axios";
import Spin from "../../../Assets/SVG/Spin";
import Input from "../../../Components/Input/Input";
import format from "./format.json";

import Image from "../../../Assets/Image/Logo.jpg";
export default function Login() {
  const [loading, setLoading] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const navigate = useNavigate();

  useEffect(() => {
    try {
      const remember = JSON.parse(localStorage.getItem("rememberMe"));
      if (remember) {
        const token = localStorage.getItem("token");
        const checkToken = async () => {};
        checkToken();
      }
    } catch (error) {
      setLoading(false);
      if (error.response) {
        addNotification(
          "Error",
          error.response.data.message || "An error occurred",
          "warning"
        );
      } else if (error.request) {
        addNotification("Error", "No response received from server", "warning");
      } else {
        addNotification(
          "Error",
          error.message || "An unknown error occurred",
          "warning"
        );
      }
    }
  }, []);

  const checkInput = () => {
    if (!formData.email) {
      addNotification("Empty field", "please enter a email", "warning");
      return false;
    }
    if (!formData.password) {
      addNotification("Empty field", "please enter a password", "warning");
      return false;
    }
    if (formData.password.length < 8) {
      addNotification(
        "Password too short",
        "password should be at least 8 characters long",
        "warning"
      );
      return false;
    }
    return true;
  };

  const callFunction = async () => {
    setLoading(true);

    if (!checkInput()) {
      setLoading(false);
      return false;
    }

    try {
      const response = await Axios.post("/login", formData);

      if (response.data.status) {
        localStorage.setItem("token", response.data.token);
        localStorage.setItem("user", JSON.stringify(response.data.data));
        if (response.data.data.type === "employee") {
          navigate("/employee/dashboard");
        } else if (response.data.data.type === "manager") {
          navigate("/manager/dashboard");
        } else if (response.data.data.type === "superuser") {
          navigate("/superadmin/dashboard");
        } else {
          navigate("/login");
        }
      } else {
        addNotification("Warning", response.data.message, "warning");
      }
    } catch (error) {
      setLoading(false);
      if (error.response) {
        addNotification(
          "Error",
          error.response.data.message || "An error occurred",
          "warning"
        );
      } else if (error.request) {
        addNotification("Error", "No response received from server", "warning");
      } else {
        addNotification(
          "Error",
          error.message || "An unknown error occurred",
          "warning"
        );
      }
    }
    setLoading(false);
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  return (
    <div className="flex items-center justify-center bg-[#e4e3e3] min-h-dvh p-3 ">
      <div className="max-w-[400px] w-[400px] min-w-[200px]">
        <div className="bg-white shadow-md rounded-md p-5 ">
          <div className="flex justify-center ">
            <img src={Image} alt="not found" className="w-[250px]" />
          </div>
          <div className="grid-cols-1 grid gap-x-4 sm:gap-y-1">
            {format.map((field, index) => (
              <div key={index}>
                <p>{field.title}</p>
                <Input
                  defaultValue={formData[field.name]}
                  onChange={handleChange}
                  name={field.target}
                  type={field.type}
                  // className="w-full h-10 outline-orange-500 border-gray-300 rounded-sm px-2 border-[2px]"
                  placeholder={field.placeholder}
                />
              </div>
            ))}
          </div>
          <div className="">
            <div className="flex items-center mb-4 float-left mt-1">
              <input
                id="default-checkbox"
                type="checkbox"
                value=""
                className="w-[18px] h-[18px] accent-orange-500 text-orange-500 bg-gray-100 border-gray-300 rounded focus:ring-orange-500 focus:ring-2"
                onClick={() => setRememberMe(!rememberMe)}
              />
              <label
                htmlFor="default-checkbox"
                className="ms-2 text-sm font-medium text-gray-900"
              >
                Remember Me
              </label>
            </div>
            <Link
              to={"/forgot-password"}
              className="text-black hover:text-gray-700 float-right"
            >
              Forgot Password?
            </Link>
          </div>
          <br />
          {!loading && (
            <button
              className="h-10 rounded-sm bg-orange-500 w-full text-white text-xl hover:bg-orange-600 outline-none focus-visible:ring-2 ring-offset-2 ring-orange-500"
              onClick={callFunction}
            >
              Continue
            </button>
          )}
          {loading && (
            <button className="h-10 rounded-sm bg-orange-500 w-full text-white text-xl hover:bg-orange-600 outline-none focus-visible:ring-2 ring-offset-2 ring-orange-500 flex items-center justify-center">
              <Spin />
            </button>
          )}
        </div>
      </div>
    </div>
  );
}
