import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from '@mui/icons-material/Facebook';
import XIcon from '@mui/icons-material/X';

export default function SocialMediaCard({ title, api }) {
  return (
    <div
      className={`p-4 ${
        title === "instagram"
          ? "bg-gradient-to-r from-[#833ab4] via-[#fd1d1d] to-[#fcb045]"
          : title === "facebook"
          ? "bg-[#4267B2]"
          : title === "x"
          ? "bg-gray-950 "
          : ""
      } w-full h-36 rounded-md`}
    >
      <div className="flex items-center justify-center">
        {title === "instagram" && <InstagramIcon style={{fontSize:"60px"}} className="text-white" />}
        {title === "facebook" && <FacebookIcon style={{fontSize:"60px"}} className="text-white" />}
        {title === "x" && <XIcon style={{fontSize:"60px"}} className="text-white" />}
      </div>
      <div className="grid grid-cols-2 text-white mx-10 font-bold">
        <div>Followers <br />34,324</div>
        <div className="ml-14">Post <br /> 45</div>
      </div>
    </div>
  );
}
