  export default function SeatButton({ title = "", type = "seat",onClick = ()=>{},id}) {
    return (
      <>
        {type === "seat" && (
          <button className="select-none w-10 rounded-md h-10 uppercase font-bold text-xl font-sans text-orange-400 border-[1px] border-orange-500" onClick={()=>onClick(id)}>
            {title}
          </button>
        )}
        {type === "blank" && (
          <button className="select-none w-10 rounded-md h-10 uppercase font-bold text-xl font-sans text-orange-400 border-[1px] border-orange-500">

          </button>
        )}
      </>
    );
  }
