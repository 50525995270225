import { useState, Fragment, useEffect } from "react";
import { IconButton, Tooltip } from "@mui/material";
import ChairIcon from "@mui/icons-material/Chair";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import SeatButton from "./seatButton";

// redux imports
import { useDispatch, useSelector } from "react-redux";
import { setValue } from "../../Database/Redux/counterSlice";
import { setValueTransaction } from "../../Database/Redux/transactionSlice";

import Loading from "../../Assets/SVG/Loading";
import { addNotification } from "../../Components/Notification/Notification";
import Token from "../../Database/Token";

export default function SeatPopup({
  title = "Select Seat",
  is_train = true,
  hover = "Seat",
  buses = [],
  gender = "none",
  trip_id = null,
  group_id = null,
  customer_id = null,
  seat_id = false,
  seat_title = false,
  seat_transaction_id = false,
}) {
  const [active, setActive] = useState(false);
  const [loading, setLoading] = useState(false);
  const [transaction, setTransaction] = useState(false);
  const [maxY, setMaxY] = useState(6);
  const dispatch = useDispatch();

  const count = useSelector((state) => state.counter.value);

  useEffect(() => {
    const yValues = buses
      .map((bus) => bus.json.map((seat) => parseInt(seat.y)))
      .flat();

    const y = Math.max(...yValues);
    console.log(y);
    setMaxY(y + 1);
  }, [buses]);

  const handelClick = () => {
    setLoading(true);
    const callFunction = async () => {
      const jsonData = {
        trip_id: trip_id,
        group_id: group_id,
        title: count,
        tripbustransaction_id: transaction,
        customer_id: customer_id,
      };
      try {
        let response = [];
        if (trip_id) {
          response = await Token.post("/seats", jsonData);
        } else {
          response = await Token.patch("/seats/" + seat_id, jsonData);
        }
        if (response.data.status) {
          addNotification(response.data.message, "Seat", "success");
        } else {
          addNotification(response.data.message, "seat", "warning");
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
        if (error.response) {
          addNotification(
            "Error",
            error.response.data.message || "An error occurred",
            "warning"
          );
        } else if (error.request) {
          addNotification(
            "Error",
            "No response received from server",
            "warning"
          );
        } else {
          addNotification(
            "Error",
            error.message || "An unknown error occurred",
            "warning"
          );
        }
      }
    };
    callFunction();
  };
  return (
    <Fragment>
      <Tooltip arrow placement="top" title={hover}>
        <div className="bg-cyan-400 cursor-pointer w-10 h-10 flex items-center justify-center rounded-md shadow-md">
          <IconButton
            className="w-10 h-10 rounded-none"
            onClick={() => setActive(!active)}
          >
            <ChairIcon className="text-white" />
          </IconButton>
        </div>
      </Tooltip>

      <div
        onClick={() => {
          setActive(false);
          // dispatch(setValue(false));
          // dispatch(setValueTransaction(false));
        }}
        className={`z-[5] w-screen h-screen overflow-y-auto fixed inset-0 flex items-center justify-center pt-20 ${
          active ? "visible" : "invisible"
        } bg-black/50`}
      >
        <div
          className={`bg-white min-w-[400px] rounded-md shadow-2xl transition-all p-4 ${
            active ? "scale-100 opacity-100" : "scale-75 opacity-0"
          }`}
          onClick={(e) => e.stopPropagation()}
        >
          <h1 className="text-5xl font-bold text-orange-500 text-center ">
            {title}
          </h1>
          <br />
          <div
            className={`grid gap-3 mx-3 ${
              is_train ? "grid-cols-[40px_1fr_40px]" : "grid-cols-1"
            }`}
          >
            <Tooltip arrow placement="top" title="Previous">
              <div className="bg-orange-500 cursor-pointer w-10 h-10 flex items-center justify-center rounded-md shadow-md">
                <IconButton className="w-10 h-10 rounded-none">
                  <KeyboardArrowLeftIcon className="text-white" />
                </IconButton>
              </div>
            </Tooltip>
            {!loading && (
              <button
                className="w-full bg-orange-500 h-10 text-white text-xl rounded-md"
                onClick={handelClick}
              >
                Save
              </button>
            )}
            {loading && (
              <button className="w-full bg-orange-500 h-10 text-white text-xl rounded-md flex items-center justify-center float-left">
                <Loading />
              </button>
            )}
            <Tooltip arrow placement="top" title="Next">
              <div className="bg-orange-500 cursor-pointer w-10 h-10 flex items-center justify-center rounded-md shadow-md">
                <IconButton className="w-10 h-10 rounded-none">
                  <KeyboardArrowRightIcon className="text-white" />
                </IconButton>
              </div>
            </Tooltip>
          </div>
          <br />
          {buses.map((bus, idx) => (
            <Fragment key={idx}>
              <p className="text-center text-xl">
                {bus.name}:- {bus.title}
              </p>
              <div className="flex items-center justify-center">
                <div
                  key={maxY}
                  style={{
                    display: "grid",
                    gridTemplateRows: `repeat(${maxY}, minmax(0, 1fr))`,
                  }}
                  className={`w-min border-gray-400 border-[1px] rounded-md  gap-1 p-3 grid-flow-col`}
                >
                  {bus.json.map((seat, index) => (
                    <div key={index}>
                      {seat.type === "seat" ? (
                        <SeatButton
                          seat_id={seat_id}
                          title={seat.title}
                          type={seat.type}
                          disable={seat.disabled}
                          activated={seat.gender === "none" ? false : true}
                          gender={seat.gender === "none" ? gender : seat.gender}
                          transaction_id={bus.transaction_id}
                          onclick={(e) => setTransaction(e)}
                          customer_id={seat.customer_id}
                          self_id={customer_id}
                        />
                      ) : (
                        <div></div>
                      )}
                    </div>
                  ))}
                </div>
              </div>
              <br />
            </Fragment>
          ))}
        </div>
      </div>
    </Fragment>
  );
}
