import { useState, useEffect } from "react";
import { useAppContext } from "../../../Database/Context";
import SuperadminSidebarNavbar from "../../../Components/Sidebar/SuperadminSidebarNavbar";
import Token from "../../../Database/Token";
import Search from "../../../Components/Search/Search";
import Table from "../../../Components/Table/Table";
import Status from "../../../Components/Table/Status";
import colName from "./colName.json";
import SearchList from "./search.json";
import qs from "qs";
import { addNotification } from "../../../Components/Notification/Notification";

export default function SuperadminCommission() {
  const [search, setSearch] = useState("");
  const [update, setUpdate] = useState(false);
  const [row, setRow] = useState(5);
  const [page, setPage] = useState(1);
  const [data, setData] = useState([]);
  const [pickups, setPickups] = useState([]);
  const [searchOutput, setSearchOutput] = useState({});
  const [searchList, setSearchList] = useState(SearchList);
  const [loading, setLoading] = useState(false);

  const [TableData, setTableData] = useState([]);

  const { dispatch } = useAppContext();

  useEffect(() => {
    const callFunction = async () => {
      try {
        const response = await Token.get("pickups");
        setPickups(response.data.data);
      } catch (e) {
        console.error(e.message);
      }
    };
    callFunction();
  }, []);

  useEffect(() => {
    if (pickups.length > 0) {
      const updatedSearchList = searchList.map((item) => {
        if (item.target === "pickup") {
          return {
            ...item,
            options: [
              { id: 0, title: "Select Pickup" },
              ...pickups.map((pickup) => ({
                id: pickup.id,
                title: pickup.location,
              })),
            ],
          };
        }
        return item;
      });
      setSearchList(updatedSearchList);
    }
  }, [pickups]);
  useEffect(() => {
    const queryData = {
      search,
      page,
      row,
    };
    const combinedData = {
      ...queryData,
      ...searchOutput,
    };
    const callFunction = async () => {
      dispatch({ type: "SET_LOADING", payload: true });
      try {
        const queryString = qs.stringify(combinedData, { encode: true });
        const response = await Token.get(`/customers?data=${queryString}`);

        setData(response.data.data.data);
        setTableData(response.data.data);
      } catch (error) {
        setLoading(false);
        if (error.response) {
          addNotification(
            "Error",
            error.response.data.message || "An error occurred",
            "warning"
          );
        } else if (error.request) {
          addNotification(
            "Error",
            "No response received from server",
            "warning"
          );
        } else {
          addNotification(
            "Error",
            error.message || "An unknown error occurred",
            "warning"
          );
        }
      }
      dispatch({ type: "SET_LOADING", payload: false });
    };
    callFunction();
  }, [search, update, row, page, searchOutput]);

  return (
    <SuperadminSidebarNavbar>
      <div className="h-[calc(100dvh-40px)] w-full p-4 overflow-y-auto">
        <div key={searchList}>
          <Search
            onSearch={(e) => {
              setSearch(e);
              setUpdate(!update);
            }}
            SearchList={searchList}
            onSearchList={(e) => setSearchOutput(e)}
          ></Search>
        </div>
        <div key={TableData}>
          <Table
            data={data}
            button={""}
            updateRow={(e) => {
              setRow(e);
              setPage(1);
            }}
            cPage={TableData.current_page}
            updatePage={(e) => setPage(e)}
            lPage={TableData.last_page}
            from={TableData.from}
            to={TableData.to}
            total={TableData.total}
          >
            <thead className="text-xs text-gray-100 uppercase bg-orange-500">
              <tr>
                {colName.length !== 0 &&
                  colName.map((data, index) => (
                    <th
                      key={index}
                      scope="col"
                      colSpan={1}
                      className="duration-300 pl-2 sm:pl-1 py-2 group h-14 min-w-[75px]"
                    >
                      {data.title}
                    </th>
                  ))}
              </tr>
            </thead>

            <tbody>
              {data.length === 0 && (
                <tr>
                  <td
                    colSpan={colName.length}
                    className="text-center text-bold text-xl text-gray-800 uppercase py-4 border-[1.5px] border-gray-300"
                  >
                    Data not found
                  </td>
                </tr>
              )}
              {data.length !== 0 &&
                data.map((list, index) => (
                  <tr
                    // className={`pl-2 ${ list.status === "debit"?"bg-[#ffdddd] hover:bg-[#ffb0b0]":"bg-[#c2ecff] hover:bg-[#95deff]"} text-slate-900 border-y-[1.7px]`}
                    className={`pl-2 text-slate-900 border-y-[1.7px] hover:bg-[#f1f1f1]`}
                    key={list.id}
                  >
                    <th
                      className="px-4 py-4 md:px-4 sm:px-2 font-medium"
                      scope="row"
                    >
                      {index + 1 + (TableData.current_page - 1) * row}
                    </th>
                    <th
                      className="px-4 py-4 md:px-4 sm:px-2 font-medium uppercase"
                      scope="row"
                    >
                      #{list.customer_id}
                    </th>

                    {colName.map((data) => (
                      <>
                        {!data.spacial && (
                          <th
                            className="px-4 py-4 md:px-4 sm:px-2 font-medium"
                            scope="row"
                          >
                            {/* <Status title={list[data.target]} /> */}
                            {list[data.target]}
                          </th>
                        )}
                      </>
                    ))}

                    <th
                      className="px-4 py-4 md:px-4 sm:px-2 font-medium"
                      scope="row"
                    >
                      <Status title={list.status} />
                    </th>
                    <th
                      className="px-4 py-4 md:px-4 sm:px-2 font-medium"
                      scope="row"
                    ></th>
                  </tr>
                ))}
            </tbody>
          </Table>
        </div>
      </div>
    </SuperadminSidebarNavbar>
  );
}
