import { useState, Fragment } from "react";
import { addNotification } from "../../Components/Notification/Notification";
import { IconButton, Tooltip } from "@mui/material";

import Token from "../../Database/Token";

import DeleteIcon from "@mui/icons-material/Delete";
import Spin from "../../Assets/SVG/Spin";

export default function DeletePopup({
  route,
  id,
  title = "Are you sure you want to delete?",
  onLoading = () => {},
  hover = "Delete",
}) {
  const [active, setActive] = useState(false);
  const [loading, setLoading] = useState(false);

  const deleteBooking = async () => {
    setLoading(true);
    try {
      const response = await Token.delete(`${route}${id}/`);

      if (response.data.status) {
        addNotification("record deleted successfully", "Delete", "success");
        setActive(false);
        onLoading();
      } else {
        addNotification("Error", response.data.message, "warning");
      }
    } catch (error) {
      setLoading(false);
      if (error.response) {
        addNotification(
          "Error",
          error.response.data.message || "An error occurred",
          "warning"
        );
      } else if (error.request) {
        addNotification("Error", "No response received from server", "warning");
      } else {
        addNotification(
          "Error",
          error.message || "An unknown error occurred",
          "warning"
        );
      }
    } finally {
      onLoading();
      setLoading(false);
    }
  };

  return (
    <Fragment>
      <Tooltip arrow placement="top" title="Delete">
        <div className="bg-red-500 cursor-pointer w-10 h-10 flex items-center justify-center rounded-md shadow-md">
          <IconButton
            className="w-10 h-10 rounded-none"
            onClick={() => setActive(!active)}
          >
            <DeleteIcon className="text-white" />
          </IconButton>
        </div>
      </Tooltip>

      <div
        className={`z-[5] w-screen h-screen fixed inset-0 flex items-center justify-center p-3 ${
          active ? "visible" : "invisible"
        } bg-black/50`}
      >
        <div
          className={`bg-white max-w[400px] w-[400px] rounded-md shadow-2xl transition-all p-3 ${
            active ? "scale-100 opacity-100" : "scale-75 opacity-0"
          }`}
          onClick={(e) => e.stopPropagation()}
        >
          <h1 className="text-2xl font-bold text-red-500">Warning</h1>
          <p className="my-3 text-lg">{title}</p>
          {!loading && (
            <button
              className="outline-none my-[3px] w-full h-10 bg-red-500 hover:bg-red-600 focus-visible:ring-2 ring-offset-2 ring-red-500 text-white text-xl rounded-md shadow-md"
              onClick={deleteBooking}
            >
              Delete
            </button>
          )}
          {loading && (
            <button
              className="outline-none my-[3px] w-full h-10 bg-red-500 hover:bg-red-600 focus-visible:ring-2 ring-offset-2 ring-red-500 text-white text-xl rounded-md shadow-md flex items-center justify-center"
              onClick={deleteBooking}
            >
              <Spin />
            </button>
          )}
          <button
            className="w-full h-10 rounded-sm my-2 text-lg bg-white border-[1.5px] border-dashed border-gray-400 text-gray-500 shadow-md outline-none hover:bg-orange-50 focus:bg-orange-50"
            onClick={() => setActive(false)}
          >
            Cancel
          </button>
        </div>
      </div>
    </Fragment>
  );
}
