

export default function Status({
  title = "pending",
  format = [
    { title: "pending", color: "yellow" },
    { title: "confirm", color: "green" },
    { title: "confirmed", color: "green" },
    { title: "completed", color: "blue" },
    { title: "canceled", color: "red" },
  ],
}) {
  const checkColor = (title) => {
    const targetFormat = format.find((item) => item.title === title);
    return targetFormat?.color;
  };
  return (
    <div
    className={`text-nowrap select-none ${
      checkColor(title) === "yellow"
        ? "bg-yellow-300 border-yellow-400 text-yellow-600"
        : checkColor(title) === "green"
        ? "bg-green-500 border-green-600 text-green-100"
        : checkColor(title) === "blue"
        ? "bg-blue-500 border-blue-600 text-blue-100"
        : "bg-red-500 border-red-600 text-red-100"
    } border-2 w-min rounded-md px-2 py-1 cursor-pointer`}
    >
      {title}
    </div>
  );
}
