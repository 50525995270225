import { useState, Fragment } from "react";
import { addNotification } from "../../Components/Notification/Notification";
import { IconButton, Tooltip } from "@mui/material";

import Token from "../../Database/Token";
import Input from "../../Components/Input/Input";
import Loading from "../../Assets/SVG/Loading";

import ReceiptIcon from "@mui/icons-material/Receipt";

export default function InvoiceCreatePopUp({
  title = "Invoice",
  onLoading = () => {},
  hover = "Deposit",
  onUpdate = () => {},
  invoiceId = null,
  paymentType = "none",
  paymentCash = 0,
  paymentOnline = 0,
  paymentRemark = "",
  id = null,
  data = [],
}) {
  const [active, setActive] = useState(false);
  const [loading, setLoading] = useState(false);
  const [method, setMethod] = useState(paymentType);
  const [cash, setCash] = useState(paymentCash);
  const [online, setOnline] = useState(paymentOnline);
  const [remark, setRemark] = useState(paymentRemark);
  const [formValue, setFormValue] = useState(data);

  const EditFunction = async () => {
    setLoading(true);
    const jsonData = {
      ...formValue,
      cash: cash,
      online: online,
      remark: remark,
      payment_mode: method,
      
    };
    console.log(jsonData);
    try {
      if (invoiceId === null) {
        var response = await Token.post("/invoices", jsonData);
      } else {
        var response = await Token.patch(`/invoices/${invoiceId}`, jsonData);
      }
      if (response.data.status) {
        addNotification("Successful booking", response.data.message, "success");
        setLoading(false);
        setActive(false);
        onUpdate();
        onLoading();
      } else {
        addNotification("Error", response.data.message, "warning");
      }
    } catch (error) {
      setLoading(false);
      if (error.response) {
        addNotification(
          "Warning",
          error.response.data.message || "An error occurred",
          "warning"
        );
      } else if (error.request) {
        addNotification("Error", "No response received from server", "warning");
      } else {
        addNotification(
          "Warning",
          error.message || "An unknown error occurred",
          "warning"
        );
      }
    }
  };

  const select = [
    { id: "none", title: "Select Payment Method" },
    { id: "online", title: "online" },
    { id: "cash", title: "cash" },
    { id: "custom", title: "custom" },
  ];
  const format = [
    { title: "Name of the person", target: "name", type: "text" },
    { title: "Mobile Number", target: "mobile_no", type: "number" },
    { title: "Number of person", target: "no_of_person", type: "number" },
    { title: "Deposit", target: "deposit", type: "number" },
    { title: "Remaining Amount", target: "remaining_amount", type: "number" },
    { title: "Add Price", target: "add_price", type: "number" },
    { title: "Discount", target: "discount", type: "number" },
  ];

  return (
    <Fragment>
      <Tooltip arrow placement="top" title={hover}>
        <div className="bg-blue-500 cursor-pointer w-10 h-10 flex items-center justify-center rounded-md shadow-md">
          <IconButton
            className="w-10 h-10 rounded-none"
            onClick={() => setActive(!active)}
          >
            <ReceiptIcon className="text-white" />
          </IconButton>
        </div>
      </Tooltip>

      <div
        onClick={() => setActive(false)}
        className={`z-[5] w-screen h-screen overflow-y-auto  fixed inset-0 flex items-center justify-center pt-10 ${
          active ? "visible" : "invisible"
        } bg-black/50`}
      >
        <div
          className={`bg-white max-w[700px] w-[700px] rounded-md shadow-2xl transition-all p-3 ${
            active ? "scale-100 opacity-100" : "scale-75 opacity-0"
          }`}
          onClick={(e) => e.stopPropagation()}
        >
          <h1 className="text-5xl font-bold text-orange-500 text-center ">
            {title}
          </h1>
          <br />

          <div className="grid grid-cols-2 gap-3">
            {format.map((list) => (
              <div>
                <p className="font-bold">{list.title}</p>
                <Input
                  type={list.type}
                  defaultValue={formValue[list.target]}
                  onChange={(e) =>
                    setFormValue((prev) => ({
                      ...prev,
                      [list.target]: e.target.value,
                    }))
                  }
                />
              </div>
            ))}
            <div>
              <p>Remark</p>
              <Input
                type="textarea"
                options={select}
                onChange={(e) => setRemark(e.target.value)}
                defaultValue={data.remark}
              />
            </div>
            <div>
              <p>Payment Type</p>
              <Input
                type="select"
                options={select}
                onChange={(e) => {
                  setMethod(e.target.value);
                }}
              />
            </div>
            {(method === "cash" || method === "custom") && (
              <div>
                <p>Cash</p>
                <Input
                  type="number"
                  options={select}
                  onChange={(e) => setCash(e.target.value)}
                  defaultValue={cash}
                />
              </div>
            )}
            {(method === "online" || method === "custom") && (
              <div>
                <p>Online</p>
                <Input
                  type="number"
                  options={select}
                  onChange={(e) => setOnline(e.target.value)}
                  defaultValue={online}
                />
              </div>
            )}
          </div>
          <p className="h-3"></p>
          {!loading && (
            <button
              className="outline-none my-[3px] w-full h-10 bg-orange-500 hover:bg-orange-600 focus-visible:ring-2 ring-offset-2 ring-red-500 text-white text-xl rounded-md shadow-md"
              onClick={EditFunction}
            >
              Save
            </button>
          )}
          {loading && (
            <button className="outline-none my-[3px] w-full h-10 bg-orange-500 hover:bg-orange-600 focus-visible:ring-2 ring-offset-2 ring-red-500 text-white text-xl rounded-md shadow-md flex items-center justify-center">
              <Loading />
            </button>
          )}
          <button
            className="w-full h-10 rounded-sm my-2 text-lg bg-white border-[1.5px] border-dashed border-gray-400 text-gray-500 shadow-md outline-none hover:bg-orange-50 focus:bg-orange-50"
            onClick={() => setActive(false)}
          >
            Cancel
          </button>
        </div>
      </div>
    </Fragment>
  );
}
