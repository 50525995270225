import { useState, useRef, useEffect, Fragment } from "react";
import { IconButton, Tooltip } from "@mui/material";

import AddLocationIcon from "@mui/icons-material/AddLocation";
import Token from "../../Database/Token";
import { addNotification } from "../../Components/Notification/Notification";
import Input from "../../Components/Input/Input";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import PickupLabel from "./PickupLabel";
import Loading from "../../Assets/SVG/Loading";

export default function PickupPopUp({ trip_id }) {
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [locations, setLocations] = useState([]);
  const [newLocation, setNewLocation] = useState("");
  const [loading, setLoading] = useState(false);
  const popupRef = useRef(null);

  const togglePopup = () => {
    setIsPopupVisible(!isPopupVisible);
  };

  const handleClickOutside = (event) => {
    if (
      popupRef.current &&
      !popupRef.current.contains(event.target) &&
      event.target.id !== "popup-button"
    ) {
      setIsPopupVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (isPopupVisible) {
      const callFunction = async () => {
        try {
          const response = await Token.get("pickups/" + trip_id);
          
          if (response.data.status) {
            setLocations(response.data.data);
          }
          
        } catch (error) {
          if (error.response) {
            addNotification(
              "Error",
              error.response.data.message || "An error occurred",
              "warning"
            );
          } else if (error.request) {
            addNotification(
              "Error",
              "No response received from server",
              "warning"
            );
          } else {
            addNotification(
              "Error",
              error.message || "An unknown error occurred",
              "warning"
            );
          }
        }
      };
      callFunction();
    }
  }, [isPopupVisible]);

  const addPickup = async () => {
    setLoading(true);
    try {
      const jsonData = {
        trip_id: trip_id,
        location: newLocation,
      };
      const response = await Token.post("pickups", jsonData);
      if (response.data.status) {
        setLocations([...locations, response.data.data]);
        addNotification("Success", "Pickup added successfully", "success");
      } else {
        addNotification("Error", response.data.message, "warning");
      }
    } catch (error) {
      if (error.response) {
        addNotification(
          "Error",
          error.response.data.message || "An error occurred",
          "warning"
        );
      } else if (error.request) {
        addNotification("Error", "No response received from server", "warning");
      } else {
        addNotification(
          "Error",
          error.message || "An unknown error occurred",
          "warning"
        );
      }
    }
    setLoading(false);
  };

  const deleteFunction = async (id) => {
    try {
      const response = await Token.delete("pickups/" + id);
      if (response.data.status) {
        setLocations(locations.filter((location) => location.id !== id));
        addNotification("Success", "Pickup deleted successfully", "success");
      } else {
        addNotification("Error", response.data.message, "warning");
      }
    } catch (error) {
      if (error.response) {
        addNotification(
          "Error",
          error.response.data.message || "An error occurred",
          "warning"
        );
      } else if (error.request) {
        addNotification("Error", "No response received from server", "warning");
      } else {
        addNotification(
          "Error",
          error.message || "An unknown error occurred",
          "warning"
        );
      }
    }
  };
  return (
    <Fragment className="relative flex items-center justify-center h-screen">
      <Tooltip
        id="popup-button"
        arrow
        placement="top"
        title="Add Pickup"
        onClick={togglePopup}
      >
        <div className="bg-green-500 cursor-pointer w-10 h-10 flex items-center justify-center rounded-md shadow-md">
          <IconButton className="w-10 h-10 rounded-none">
            <AddLocationIcon className="text-white" />
          </IconButton>
        </div>
      </Tooltip>

      {isPopupVisible && (
        <div
          ref={popupRef}
          className="absolute top-20 left-1/2 transform -translate-x-1/2 p-3 bg-white shadow-md border rounded-md"
        >
          <div className="grid grid-cols-[1fr_40px] gap-2">
            <Input
              id="popup-button"
              onChange={(e) => setNewLocation(e.target.value)}
              onEnter={addPickup}
            />
            {!loading && (
              <Tooltip
                id="popup-button"
                arrow
                placement="top"
                title="Add Pickup"
                onClick={addPickup}
              >
                <div className="bg-orange-500 cursor-pointer w-10 h-10 flex items-center justify-center rounded-md shadow-md">
                  <IconButton className="w-10 h-10 rounded-none">
                    <AddCircleIcon className="text-white" />
                  </IconButton>
                </div>
              </Tooltip>
            )}
            {loading && (
              <Tooltip
                id="popup-button"
                arrow
                placement="top"
                title="Add Pickup"
              >
                <div className="bg-orange-500 cursor-pointer w-10 h-10 flex items-center justify-center rounded-md shadow-md">
                  <IconButton className="w-10 h-10 rounded-none">
                    <Loading />
                  </IconButton>
                </div>
              </Tooltip>
            )}
          </div>
          <div className="min-h-10 border-[1px] border-gray-400 mt-3 rounded-sm py-1 max-h-28 scroll-bar scroll-smooth p-1 overflow-y-auto">
            {locations.length === 0 && (
              <div className="h-7 my-1 flex items-center pl-1 rounded-sm bg-gray-300 relative">Pickup not found</div>
            )}
            {locations.length !== 0 &&
              locations.map((list, index) => (
                <PickupLabel
                  id={list.id}
                  title={list.location}
                  key={index}
                  onDelete={(id) => deleteFunction(id)}
                />
              ))}
          </div>
        </div>
      )}
    </Fragment>
  );
}
